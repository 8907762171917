// import React, { useState, useEffect, useContext } from "react";
// import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import Placeholder from "../../../../../asesst/placeholder.jpeg";
// import { createSlug } from "../../../../../helper";
// import { ContentContext } from "../../../../../store";
// import { motion } from "framer-motion";
import Placeholder from "../../../../../asesst/placeholder.jpeg";
import { createSlug } from "../../../../../helper";

const ProductCard = (product) => {
  const { image, itemname, count, combinations, id, retailPrice } = product;

  const productImage = image || Placeholder;
  const variant = combinations?.[0] || {};

  return `<section class="card-product prduct-card card-slider-item">
    <section class="card-media media--hover-effect">
      <img src="${productImage}" />
      <img
        src="${productImage}"
        sizes="(min-width: 1100px) 960px, (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)"
        alt="${itemname}"
        size="960"
        loading="lazy"
        class="motion-reduce lazyloaded"
      />

      <a class="card-link" href="/product/${createSlug(itemname)}"></a>

      <div class="stock-tag ${variant.count || count ? "d-none" : "d-flex"}">
        <p>Out Of Stock</p>
      </div>
    </section>

    <section class="position-relative">
      <section class="card-action">
        <section class="variants">
          ${
            variant.count || count
              ? `<button
                  class="button button-ATC colored-btn"
                  image-src="${productImage}"
                  item-id="${id}"
                  item-name="${itemname?.replaceAll(" ", "-")}"
                  retail-price="${variant.retailPrice || retailPrice}"
                  variant="${variant.variantName || ""}"
                >
                  Quick Add
                </button>`
              : ""
          }
        </section>
      </section>

      <h3 class="card__heading">
        <a class="card-title link-underline card-title-ellipsis card-title-change" href="/product/${createSlug(
          itemname
        )}">
          ${itemname} ${variant.variantName ? `- ${variant.variantName}` : ""}
        </a>
      </h3>

      <section class="card-price">
      <span class="fw-bold unselectable-block text-dark">Rs.</span>
        <span class="money">${variant.retailPrice || retailPrice}</span>
      </section>
    </section>
  </section>`;
};
export default ProductCard;
