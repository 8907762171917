import React, { useState } from "react";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EmailIcon, PhoneIcon, UserIcon } from "../../../../component/icon";
import { accountRegister } from "../../../../apis/auth";
import Loader from "../../../../component/Loader";
import CustomNavbar from "../../Component/Navber";
import Footer from "../../Component/Footer";
import ContactNumberInput from "../../../../component/contactInput";

const generateCaptcha = (length = 6) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let captcha = "";
  for (let i = 0; i < length; i++) {
    captcha += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return captcha;
};
const Register = () => {
  const [data, setData] = useState({ type: "Online" });
  const [loading, setLoading] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState(null); // Use `null` as default state if not set yet

  const handleChange = (event) => {
    data[event.target.name] = event.target.value;
    setData(data);
  };

  const handleTypeChange = (type) => {
    setData({ ...data, type });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!data?.name) {
        return toast.error("Please fill in the Name.");
      } else if (!data?.username) {
        return toast.error("Please fill in the Username.");
      } else if (!data?.mobile || data?.mobile?.length < 10) {
        return toast.error("Please fill in the Mobile Number.");
      } else {
        setLoading(true);
        const response = await accountRegister(data);
        if (
          response &&
          response.message === "Your account registered successfully."
        ) {
          setRegistrationStatus("success");
          // toast.success("Your account registered successfully.");
        } else {
          toast.error("Unexpected response format");
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data) {
        if (
          error.response.data.message ===
          "Registration blocked due to repeated attempts."
        ) {
          setRegistrationStatus("blocked");
        }
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred");
      }
    }
  };

  return (
    <>
      <CustomNavbar />
      <div className="main__box__login">
        <div className="login-container">
          <div className="login-right">
            <div className="login-right-content">
              <div className="login-sub-content">
                <h3>Why Register?</h3>
                <p>
                  Registering with Tijarah Soft gives you access to powerful
                  tools for managing your business, both online and offline.
                  Enjoy seamless inventory management, sales tracking, and more,
                  all in one place.
                </p>

                <h3>Account Type</h3>
                <p>
                  Choose between <strong>Offline</strong> and{" "}
                  <strong>Offline & Online</strong> account types. If your
                  business operates both in-store and online, selecting "Offline
                  & Online" will enable features that integrate both
                  environments seamlessly.
                </p>

                <h3>Need Help?</h3>
                <p>
                  If you have any questions or encounter any issues during the
                  registration process, feel free to contact our support team at{" "}
                  <a href="mailto:support@tijarahsoft.com">
                    support@tijarahsoft.com
                  </a>
                  . We're here to help!
                </p>
              </div>
            </div>
          </div>
          <div className="login-left">
            {registrationStatus === "success" ? (
              <div className="success-message mt-2">
                {/* Success message content */}
                <div className="blocked-message mt-2">
                  <img
                    src="https://www.freeiconspng.com/thumbs/success-icon/success-icon-10.png"
                    width="100px"
                    height="100px"
                  />
                  <h2 className="block_main_heading pt-2 text-success  pt-4">
                    Registration successfully
                  </h2>
                  <p className="text-dark fs-5">
                    Your account has been successfully registered! We’ve sent
                    your Tijarah Soft login credentials to the WhatsApp number
                    you provided. Please check your WhatsApp and start growing
                    your business with Tijarah Soft. If you encounter any
                    issues, feel free to contact our support team via WhatsApp
                    at{" "}
                    <a href="https://wa.me/+923424652710" target="_blank">
                      03424652710
                    </a>{" "}
                    or email us at{" "}
                    <a href="mailto:support@tijarahsoft.com">
                      support@tijarahsoft.com
                    </a>
                    .
                  </p>
                </div>{" "}
              </div>
            ) : registrationStatus === "blocked" ? (
              <div className="blocked-message mt-2">
                {/* Blocked message content */}
                <div className="blocked-message mt-2">
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/148/148766.png"
                    width="100px"
                    height="100px"
                  />
                  <h2 className="block_main_heading pt-2 text-danger pt-4">
                    Registration Failed
                  </h2>
                  <p className="text-dark fs-5">
                    Your account registration has been blocked due to multiple
                    attempts. If you believe you did not make repeated attempts,
                    please contact our support team via WhatsApp at{" "}
                    <a href="https://wa.me/+923424652710" target="_blank">
                      03424652710
                    </a>{" "}
                    or email us at{" "}
                    <a href="mailto:support@tijarahsoft.com">
                      support@tijarahsoft.com
                    </a>
                    .
                  </p>
                </div>
              </div>
            ) : (
              <>
                <div className="login-header">
                  <h1>WELCOME TO</h1>
                  <h2>REGISTER</h2>
                </div>
                <div className="login-form">
                  <div className="input-wrapper d-flex gap-2">
                    <UserIcon color="black" />
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-wrapper d-flex gap-2">
                    <UserIcon color="red" />
                    <input
                      type="text"
                      placeholder="Username"
                      name="username"
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="input-wrapper d-flex gap-2">
                    <PhoneIcon />
                    <input
                      type="text"
                      placeholder="Whatsapp Number"
                      name="mobile"
                      onChange={handleChange}
                    />
                  </div> */}
                  <div className="mb-4">
                    <ContactNumberInput
                      data={data}
                      setData={setData}
                      name="mobile"
                    />
                  </div>
                  <div className="input-wrapper d-flex gap-2">
                    <EmailIcon />
                    <input
                      type="email"
                      placeholder="Email (Optional)"
                      name="email"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="type-selector">
                    <button
                      className={`type-button ${
                        data?.type === "Offline" ? "active" : ""
                      }`}
                      onClick={() => handleTypeChange("Offline")}
                    >
                      Basic
                    </button>
                    <button
                      className={`type-button ${
                        data?.type === "Online" ? "active" : ""
                      }`}
                      onClick={() => handleTypeChange("Online")}
                    >
                      Pro
                    </button>
                  </div>
                  <button
                    disabled={loading}
                    onClick={handleSubmit}
                    className="login-button mt-4"
                    style={{ backgroundColor: loading ? "#604BE8" : "" }}
                  >
                    {loading ? <Loader /> : "Submit"}
                  </button>
                </div>
                <ToastContainer />
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
