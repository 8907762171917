import axios from "axios";
import { BASE_URL } from "..";

export const fetchDomainInfo = async (domain) => {
  if (!domain?.includes("tijarahsoft") && !domain?.includes("localhost")) {
    const response = await axios.get(`${BASE_URL}/domains/info/${domain}`, {
      headers: {
        domain,
      },
    });
    if (response.status === 200) {
      return response;
    } else {
      return response.data.message;
    }
  } else {
    return ""
  }
};
