import React, { useEffect, useState, useMemo, useContext } from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import AnnouncementBar from "../../Component/AnnouncementBar";
import Navbar from "../../Component/Navbar";
import Banner from "../../Screens/Cart/Component/Banner";
import Footer from "../../Component/Footer";
import BestProduct from "../../Component/BestProduct";
import Placeholder from "../../../../asesst/placeholder.jpeg";
import { toast } from "react-toastify";
import { createOrder } from "../../../../apis/orders";
import { ContentContext } from "../../../../store";
import OpenCart from "../../Component/OpenCart";
import { createSlug, generateWhatsAppUrl } from "../../../../helper";
import WhatsappBtn from "../../Component/WhatsappBtn";
import { WhatsappIcon } from "../../../../component/icon";
import { citiesData } from "../../../../constant";
import { fetchShipping } from "../../../../apis/shipping";
import EmptyCart from "../Cart/Component/EmptyCart";
import ContactNumberInput from "../../../../component/contactInput";
function CheckoutOrder({ domain }) {
  const { products, domainInfo } = useContext(ContentContext);
  const [cartProducts, setCartProducts] = useState([]);
  const [shippingCharges, setShippingCharges] = useState(0);
  const [city, setCity] = useState("Karachi");
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };


  const fetchShippingCharges = async () => {
    try {
      const response = await fetchShipping(domain, city);
      if (response?.amount) {
        setShippingCharges(response?.amount);
      }
    } catch (error) { }
  };

  useEffect(() => {
    const storedProducts = localStorage.getItem(`${domain}-carts`);
    let cartproducts = storedProducts ? JSON.parse(storedProducts) : [];
    setCartProducts(cartproducts);
  }, []);

  useEffect(() => {
    fetchShippingCharges();
  }, []);

  // Calculate subtotal using useMemo for performance optimization
  const subtotal = useMemo(() => {
    return cartProducts?.reduce((acc, product) => {
      return acc + Number(product.retailPrice) * product.selectedQty;
    }, 0);
  }, [cartProducts]);

  const handleCheckout = async (isWhatsapp = false) => {
    try {
      // Validate required fields
      if (!data?.name) {
        return toast.error("Please enter your name");
      }
      if (!data?.address) {
        return toast.error("Please enter your address");
      }
      if (!data?.orderNote) {
        return toast.error("Please enter your ordernote");
      }
      if (!data?.mobile) {
        return toast.error("Please enter your mobile number");
      }
      console.log(data, 'data')
      // Prepare product IDs for the order 
      const products = cartProducts?.map((product) => {
        return {
          id: product?.id,
          name: product?.itemname,
          qty: product?.selectedQty,
          amount: Number(product?.retailPrice) * product?.selectedQty,
          variant: product?.selectedVariant,
        };
      });

      if (isWhatsapp) {
        const url = generateWhatsAppUrl(
          domainInfo?.whatsapp,
          data?.name,
          subtotal + shippingCharges,
          data?.address,
          data?.orderNote,
          data?.mobile,
          city,
          shippingCharges,
          products
        );
        localStorage.removeItem(`${domain}-carts`);
        window.open(url, "_blank", "noopener,noreferrer");
      } else {
        const response = await createOrder(domain, {
          ...data,
          products,
          amount: subtotal + shippingCharges,
          shipping: shippingCharges,
          city,
        });
        if (response) {
          localStorage.removeItem(`${domain}-carts`);
          toast.success(response?.message);
          navigate(`/thank-you/${response?.order?.orderNumber}`);
        }
      }
    } catch (error) {
      // Handle errors gracefully and log the exact issue
      const errorMessage =
        error.response?.data?.message || "An error occurred during checkout.";
      toast.error(errorMessage);
      console.error("Checkout Error: ", error);
    }
  };

  useEffect(() => {
    if (domainInfo?.tawkTo) {
      // Parse and insert the script into the document
      const scriptTag = document.createElement("div");
      scriptTag.innerHTML = domainInfo.tawkTo; // Assuming `tawkTo` contains the HTML with the script
      const scriptElement = scriptTag.querySelector("script");

      // Execute the script if available
      if (scriptElement) {
        const newScript = document.createElement("script");
        newScript.type = "text/javascript";
        newScript.async = true;
        newScript.innerHTML = scriptElement.innerHTML; // Set script content
        document.body.appendChild(newScript);

        // Clean up the script when component unmounts
        return () => {
          document.body.removeChild(newScript);
        };
      }
    }
  }, [domainInfo?.tawkTo]);

  const handleChangeCity = async (event) => {
    try {
      const value = event.target.value;
      setCity(value);
      const response = await fetchShipping(domain, value);
      if (response?.amount) {
        setShippingCharges(response?.amount);
      }
    } catch (error) { }
  };

  return (
    <>
      {domainInfo?.topNavbar && (
        <AnnouncementBar
          note={domainInfo?.topNavbarNote}
          mobile={domainInfo?.topNavbarMobile}
          email={domainInfo?.topNavbarEmail}
        />
      )}
      <Navbar domain={domain} logo={domainInfo?.logo} />
      <Banner lable="Check Out" />

      {cartProducts.length === 0 ? (
        <div className="min-height">
          {" "}
          <EmptyCart />
        </div>
      ) : (
        <div className="check_dtail_main">
          {/* <div><h3 cl>sddcx</h3></div> */}
          <div className="ino_box_grpun ">
            <h1 className="pt-5">Customer Information</h1>
            <div className="inp_group">
              <lable className="w-100">
                <span className="fs-5 text-dark px-2">Name</span>
                <div className="full_width_inp">
                  <input
                    onChange={handleChange}
                    name="name"
                    type="text"
                    placeholder="Name"
                  />
                </div>
              </lable>
              <div className="d-flex gap-2 w-100">
                <label className="w-100">
                  <span className="fs-5 text-dark px-2">Address</span>
                  <div className="inp_box w-100">
                    <input
                      onChange={handleChange}
                      name="address"
                      type="text"
                      placeholder="Address"
                    />
                  </div>
                </label>

                <label className="w-100">
                  <span className="fs-5 text-dark px-2">City</span>

                  <div className="inp_box w-100">
                    <select value={city} onChange={handleChangeCity}>
                      {citiesData?.map((city) => {
                        return <option value={city.value}>{city.label}</option>;
                      })}
                    </select>
                  </div>
                </label>
              </div>
              <div className="d-flex gap-2 w-100">
                <lable className="w-100">
                  <span className="fs-5 text-dark px-2">Phone Number</span>

                  {/* <div className="inp_box w-100">

                  <input
                    onChange={handleChange}
                    name="mobile"
                    type="number"
                    placeholder="Mobile Number"
                  />
                </div> */}
                  <div>
                    <ContactNumberInput
                      name="mobile"
                      data={data}
                      setData={setData}
                      bgColor="#f1f1f1"
                    />
                  </div>
                </lable>
                <label className="w-100">
                  <span className="fs-5 text-dark px-2">Email</span>

                  <div className="inp_box w-100">
                    <input
                      onChange={handleChange}
                      name="email"
                      type="email"
                      placeholder="Email (Optional)"
                    />
                  </div>
                </label>

              </div>
              <div className="w-100">
                <h3 className='not_order_head'>Add Order Note</h3>
                <textarea onChange={handleChange}
                  name="orderNote" placeholder='how can we help you ?' className='not_down_product p-4 fs-5'></textarea>
              </div>
              {/* <div>
                <label>
                  <textarea onChange={handleChange}
                    name="ordernote">

                  </textarea>
                </label>
              </div> */}
            </div>
          </div>
          <div>
            <div className="Check_out_container">
              <div className="shipping__head">
                <div>
                  <p className="shipping_text">Checkout</p>
                </div>
              </div>
              <div className="px-4"></div>
              <div className="">
                <table className="w-100">
                  <thead className="table_head">
                    <tr>
                      <th className="text-start procut_table_heading">
                        Product
                      </th>
                      <th className="procut_table_heading">Price</th>
                      <th className="procut_table_heading">Quantity</th>
                      <th className="procut_table_heading">Total</th>
                    </tr>
                  </thead>
                  {cartProducts?.map((item, index) => (
                    <tbody key={index} className="table_body">
                      <tr>
                        <td>
                          <div className=" table_img_product ">
                            <div>
                              <Link
                                to={`/product/${createSlug(item?.itemname)}`}
                                className="hdt-page-cart__img hdt-block hdt-relative hdt-oh"
                                style={{ "--aspect-ratioapt": 1.0 }}
                              >
                                <img
                                  src={item?.image ?? Placeholder}
                                  alt=""
                                  width="70"
                                  height="700"
                                  loading="lazy"
                                  sizes="(max-width: 767px) 80px, 160px"
                                />
                              </Link>
                            </div>
                            <div className="product-info">
                              <Link
                                to={`/product/${createSlug(item?.itemname)}`}
                              >
                                <h5 className="product_details2">
                                  {item?.itemname} -{" "}
                                  {item?.selectedVariant && (
                                    <span className="product-info">
                                      {item?.selectedVariant}
                                    </span>
                                  )}
                                </h5>
                              </Link>
                            </div>
                          </div>
                        </td>
                        <td className="product_prices_text">
                          {`${item?.retailPrice ? Number(item?.retailPrice) : "00"
                            }`}
                        </td>
                        <td className="product_add">
                          <span className="product_prices_text">
                            {item?.selectedQty}
                          </span>
                        </td>
                        <td className="product_prices_text">
                          {`${item?.retailPrice && item?.selectedQty
                            ? Number(item.retailPrice) *
                            Number(item.selectedQty)
                            : "00"
                            }`}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
              <div className="mt-3 d-flex align-items-center">
                <div>
                  <input placeholder="Coupon Code" className="apply_inp" />
                </div>
                <div>
                  <button className="apply__btn m-2 mt-0">Apply</button>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="subTotal_items">
                    Subtotal - {cartProducts?.length} Items
                  </p>
                </div>
                <div>
                  <p className="Subtotal_price_items text-dark fs-5">
                    RS: {subtotal}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="subTotal_items">Shipping</p>
                </div>
                <div>
                  <p className="Subtotal_price_items text-dark fs-5">
                    RS: {shippingCharges}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="subTotal_items">Total</p>
                </div>
                <div>
                  <p className="Subtotal_price_items text-dark fs-5">
                    RS: {subtotal + shippingCharges}{" "}
                  </p>
                </div>
              </div>
              <div className="product_title">
                <div className="check_out_parent">
                  <button
                    onClick={() => handleCheckout()}
                    className="Check_out_button "
                  >
                    Place Order
                  </button>
                </div>
                {domainInfo?.whatsapp && domainInfo?.isWhatsappOrderAllowed && (
                  <button
                    className="Check_out_button Whatsapp-btn"
                    onClick={() => handleCheckout(true)}
                  >
                    {" "}
                    Order Via Whatsapp
                    {/* <img src="https://www.weloveguyana.com/assets/images/order-via-whatsapp-2x.png" /> */}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <BestProduct initialVisible={3} products={products} domain={domain} />
      <Footer
        credit={domainInfo?.footerCredit}
        logo={domainInfo?.footerLogo}
        socialMedia={domainInfo?.socialMedia}
        isSocialMedia={domainInfo?.isSocialMedia}
      />
      <OpenCart domain={domain} initialProducts={products} />
      {domainInfo?.isWhatsappMessageAllowed && domainInfo?.whatsapp && (
        <WhatsappBtn number={domainInfo?.whatsapp} />
      )}
    </>
  );
}

export default CheckoutOrder;
