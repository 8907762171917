import React from 'react';

// Example SVG Icon 1: Arrow
export const BackIcon = () => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24.5" cy="24.5" r="24.5" fill="#D9D9D9" />
    <path d="M26.7475 17.7729L21.9504 23.9902L26.9997 29.9389" stroke="black" strokeWidth="2" />
  </svg>

);
export const DeliveryIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M240-160q-50 0-85-35t-35-85H40v-440q0-33 23.5-56.5T120-800h560v160h120l120 160v200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T280-280q0-17-11.5-28.5T240-320q-17 0-28.5 11.5T200-280q0 17 11.5 28.5T240-240ZM120-360h32q17-18 39-29t49-11q27 0 49 11t39 29h272v-360H120v360Zm600 120q17 0 28.5-11.5T760-280q0-17-11.5-28.5T720-320q-17 0-28.5 11.5T680-280q0 17 11.5 28.5T720-240Zm-40-200h170l-90-120h-80v120ZM360-540Z" /></svg>

);
export const ReturnBoxIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" /></svg>

);


export const UserIcon = ({ color = "#5f6368" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="#5f6368"><path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" /></svg>
);
export const CheckoutIcon = ({ color = "#5f6368", size = "24px" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width={size} fill={color}>
    <path d="M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z"/>
  </svg>
);
export const PaymetIcon = ({ color = "#5f6368", size = "24px" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z"/></svg>
);
export const ReportIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240ZM330-120 120-330v-300l210-210h300l210 210v300L630-120H330Zm34-80h232l164-164v-232L596-760H364L200-596v232l164 164Zm116-280Z"/></svg>
);
export const CloudIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"width={size} fill={color}><path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q25-92 100-149t170-57q117 0 198.5 81.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H520q-33 0-56.5-23.5T440-240v-206l-64 62-56-56 160-160 160 160-56 56-64-62v206h220q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h100v80H260Zm220-280Z"/></svg>
);
export const AnlyticsIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M280-280h80v-200h-80v200Zm320 0h80v-400h-80v400Zm-160 0h80v-120h-80v120Zm0-200h80v-80h-80v80ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/></svg>
);
export const TrackingIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M120-240q-33 0-56.5-23.5T40-320q0-33 23.5-56.5T120-400h10.5q4.5 0 9.5 2l182-182q-2-5-2-9.5V-600q0-33 23.5-56.5T400-680q33 0 56.5 23.5T480-600q0 2-2 20l102 102q5-2 9.5-2h21q4.5 0 9.5 2l142-142q-2-5-2-9.5V-640q0-33 23.5-56.5T840-720q33 0 56.5 23.5T920-640q0 33-23.5 56.5T840-560h-10.5q-4.5 0-9.5-2L678-420q2 5 2 9.5v10.5q0 33-23.5 56.5T600-320q-33 0-56.5-23.5T520-400v-10.5q0-4.5 2-9.5L420-522q-5 2-9.5 2H400q-2 0-20-2L198-340q2 5 2 9.5v10.5q0 33-23.5 56.5T120-240Z"/></svg>
);
export const AtmIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M415-360v-180h-90v-60h240v60h-90v180h-60Zm-335 0v-200q0-17 11.5-28.5T120-600h120q17 0 28.5 11.5T280-560v200h-60v-60h-80v60H80Zm60-120h80v-60h-80v60Zm480 120v-200q0-17 11.5-28.5T660-600h180q17 0 28.5 11.5T880-560v200h-60v-180h-40v140h-60v-140h-40v180h-60Z"/></svg>
);
export const ProfileIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z"/></svg>
);
export const HistryIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M480-120q-138 0-240.5-91.5T122-440h82q14 104 92.5 172T480-200q117 0 198.5-81.5T760-480q0-117-81.5-198.5T480-760q-69 0-129 32t-101 88h110v80H120v-240h80v94q51-64 124.5-99T480-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-480q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480-120Zm112-192L440-464v-216h80v184l128 128-56 56Z"/></svg>
);
export const LoyaltyIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M160-440v80h640v-80H160Zm0-440h640q33 0 56.5 23.5T880-800v440q0 33-23.5 56.5T800-280H640v200l-160-80-160 80v-200H160q-33 0-56.5-23.5T80-360v-440q0-33 23.5-56.5T160-880Zm0 320h640v-240H160v240Zm0 200v-440 440Z"/></svg>
);
export const ControlIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M560-320h80v-120h120v-80H640v-120h-80v120H440v80h120v120ZM240-140Q131-178 65.5-271.5T0-480q0-115 65.5-208.5T240-820v88q-74 35-117 103T80-480q0 81 43 149t117 103v88Zm360 20q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T240-480q0-75 28.5-140.5t77-114q48.5-48.5 114-77T600-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T960-480q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T600-120Zm0-360Zm0 280q117 0 198.5-81.5T880-480q0-117-81.5-198.5T600-760q-117 0-198.5 81.5T320-480q0 117 81.5 198.5T600-200Z"/></svg>
);
export const TransferIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M620-163 450-333l56-56 114 114 226-226 56 56-282 282Zm220-397h-80v-200h-80v120H280v-120h-80v560h240v80H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h167q11-35 43-57.5t70-22.5q40 0 71.5 22.5T594-840h166q33 0 56.5 23.5T840-760v200ZM480-760q17 0 28.5-11.5T520-800q0-17-11.5-28.5T480-840q-17 0-28.5 11.5T440-800q0 17 11.5 28.5T480-760Z"/></svg>
);
export const EncryptionIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M420-360h120l-23-129q20-10 31.5-29t11.5-42q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 23 11.5 42t31.5 29l-23 129Zm60 280q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Zm0-84q104-33 172-132t68-220v-189l-240-90-240 90v189q0 121 68 220t172 132Zm0-316Z"/></svg>
);
export const RecoveryIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m676-140-56-56 123-124H640v-80h240v240h-80v-104L676-140Zm-456 40q-17 0-34.5-10.5T160-135L60-310q-8-14-8-34.5t8-34.5l260-446q8-14 25.5-24.5T380-860h200q17 0 34.5 10.5T640-825l182 312q-23-6-47.5-8t-48.5 2L574-780H386L132-344l94 164h316q11 23 25.5 43t33.5 37H220Zm70-180-29-51 183-319h72l101 176q-17 13-31.5 28.5T560-413l-80-139-110 192h164q-7 19-10.5 39t-3.5 41H290Z"/></svg>
);
export const SpecificIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M280-80q-31 0-54-20t-26-51l-80-729h720l-80 729q-3 31-26 51t-54 20H280Zm-53-560h147q21-19 48-29.5t58-10.5q31 0 58 10.5t48 29.5h147l17-160H209l18 160Zm253 200q33 0 56.5-23.5T560-520q0-33-23.5-56.5T480-600q-33 0-56.5 23.5T400-520q0 33 23.5 56.5T480-440ZM236-560l44 400h400l44-400h-89q2 10 3.5 19.5T640-520q0 66-47 113t-113 47q-66 0-113-47t-47-113q0-11 1.5-20.5T325-560h-89Zm44 400h45-5 320-5 45-400Z"/></svg>
);
export const AccesIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M480-720q-33 0-56.5-23.5T400-800q0-33 23.5-56.5T480-880q33 0 56.5 23.5T560-800q0 33-23.5 56.5T480-720ZM360-80v-520q-60-5-122-15t-118-25l20-80q78 21 166 30.5t174 9.5q86 0 174-9.5T820-720l20 80q-56 15-118 25t-122 15v520h-80v-240h-80v240h-80Z"/></svg>
);
export const ActivtyIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="m368-320 112-84 110 84-42-136 112-88H524l-44-136-44 136H300l110 88-42 136ZM160-160q-33 0-56.5-23.5T80-240v-135q0-11 7-19t18-10q24-8 39.5-29t15.5-47q0-26-15.5-47T105-556q-11-2-18-10t-7-19v-135q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v135q0 11-7 19t-18 10q-24 8-39.5 29T800-480q0 26 15.5 47t39.5 29q11 2 18 10t7 19v135q0 33-23.5 56.5T800-160H160Zm0-80h640v-102q-37-22-58.5-58.5T720-480q0-43 21.5-79.5T800-618v-102H160v102q37 22 58.5 58.5T240-480q0 43-21.5 79.5T160-342v102Zm320-240Z"/></svg>
);
export const LoginIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"  width={size} fill={color}><path d="M480-120v-80h280v-560H480v-80h280q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H480Zm-80-160-55-58 102-102H120v-80h327L345-622l55-58 200 200-200 200Z"/></svg>
);
export const OpraionIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"width={size} fill={color}><path d="M240-360h80q17 0 28.5-11.5T360-400v-160q0-17-11.5-28.5T320-600h-80q-17 0-28.5 11.5T200-560v160q0 17 11.5 28.5T240-360Zm20-60v-120h40v120h-40Zm140 60h120q17 0 28.5-11.5T560-400v-160q0-17-11.5-28.5T520-600H400v240Zm60-60v-120h40v120h-40Zm140 60h120q17 0 28.5-11.5T760-400v-60q0-17-11.5-31.5T720-506h-60v-34h100v-60H640q-17 0-28.5 11.5T600-560v60q0 17 11.5 30.5T640-456h60v36H600v60ZM160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h640v-480H160v480Zm0 0v-480 480Z"/></svg>
);
export const FlexibleIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M80-80v-800h80v800H80Zm440-200v-400h120v400H520Zm-240 0v-400h120v400H280Z"/></svg>
);
export const SupportIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M440-120v-80h320v-284q0-117-81.5-198.5T480-764q-117 0-198.5 81.5T200-484v244h-40q-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v49q0 33-23.5 56.5T760-120H440Zm-80-280q-17 0-28.5-11.5T320-440q0-17 11.5-28.5T360-480q17 0 28.5 11.5T400-440q0 17-11.5 28.5T360-400Zm240 0q-17 0-28.5-11.5T560-440q0-17 11.5-28.5T600-480q17 0 28.5 11.5T640-440q0 17-11.5 28.5T600-400Zm-359-62q-7-106 64-182t177-76q89 0 156.5 56.5T720-519q-91-1-167.5-49T435-698q-16 80-67.5 142.5T241-462Z"/></svg>
);
export const OfflineIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960"width={size} fill={color}><path d="M805-69 411-463q-54 13-99.5 42T232-357l-63-63q37-37 76.5-65t94.5-50L229-646q-47 23-89 54.5T63-526L0-589q36-37 77-69t84-55l-92-92 43-43 736 736-43 43Zm-325-58q-36 0-63-27t-27-63q0-36 27-63t63-27q36 0 63 27t27 63q0 36-27 63t-63 27Zm248-230q-33-32-60-51.5T599-447L486-560q95 2 167.5 39T791-420l-63 63Zm169-169q-88-84-192.5-134T480-710q-37 0-71 4.5T352-693l-73-73q44-16 95.5-25t105.5-9q140 0 263.5 58T960-589l-63 63Z"/></svg>
);
export const DataIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M480-80q-140-35-230-162.5T160-522v-238l320-120 320 120v238q0 152-90 279.5T480-80Zm0-62q106-35 175.5-128.5T737-480H480v-335l-260 97v196q0 12 .5 20.5T223-480h257v338Z"/></svg>
);
export const BarcodeIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M40-120v-182h60v122h122v60H40Zm697 0v-60h122v-122h60v182H737ZM153-231v-499h80v499h-80Zm121 0v-499h42v499h-42Zm122 0v-499h83v499h-83Zm125 0v-499h121v499H521Zm163 0v-499h42v499h-42Zm83 0v-499h38v499h-38ZM40-658v-182h182v60H100v122H40Zm819 0v-122H737v-60h182v182h-60Z"/></svg>
);
export const ReceiptIcon = ({ color = "#5f6368", size = "24px" }) => (
<svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width={size} fill={color}><path d="M222-80q-43.75 0-74.37-30.63Q117-141.25 117-185v-125h127v-570l59.8 60 59.8-60 59.8 60 59.8-60 59.8 60 60-60 60 60 60-60 60 60 60-60v695q0 43.75-30.62 74.37Q781.75-80 738-80H222Zm516-60q20 0 32.5-12.5T783-185v-595H304v470h389v125q0 20 12.5 32.5T738-140ZM357-622v-60h240v60H357Zm0 134v-60h240v60H357Zm333-134q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9Zm0 129q-12 0-21-9t-9-21q0-12 9-21t21-9q12 0 21 9t9 21q0 12-9 21t-21 9ZM221-140h412v-110H177v65q0 20 12.65 32.5T221-140Zm-44 0v-110 110Z"/></svg>
);

export function ItemsIcon({ color ,size = "24px" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height='35px' fill={color} viewBox="0 0 18 18" >
      <path
        d="M9.0005 4.31504L2.25559 2.05079L7.80846 0.193144C8.57815 -0.0643812 9.41085 -0.0643812 10.1805 0.193144L15.7462 2.0553L9.0005 4.31504ZM6.61341 8.54914L8.24506 5.82028L2.2841 3.8256C1.82123 3.67048 1.31411 3.85948 1.0628 4.2789L0.217338 5.69453C-0.283035 6.53187 0.117563 7.61844 1.04029 7.92717L4.85198 9.20274C5.51889 9.42563 6.25182 9.1538 6.61341 8.54914ZM2.24284 2.04627V2.05455L2.25559 2.05079L2.24284 2.04627ZM8.24431 18V8.74718L7.89923 9.32398C7.34259 10.2547 6.36585 10.7886 5.33735 10.7886C5.01852 10.7886 4.69519 10.7381 4.37711 10.6312L1.49265 9.66659V13.0987C1.49265 14.7192 2.52566 16.1582 4.05753 16.6702L7.3846 17.7831C7.66517 17.8743 7.95324 17.9488 8.24431 17.9992V18ZM17.7867 5.71938L16.9254 4.2789C16.6749 3.85948 16.167 3.67048 15.7041 3.8256L9.74393 5.81953L11.3756 8.54839C11.7372 9.1538 12.4694 9.42563 13.1363 9.20199L16.9757 7.91738C17.8842 7.61317 18.2788 6.54316 17.7859 5.71863L17.7867 5.71938ZM12.6516 10.7893C11.6231 10.7893 10.6464 10.2562 10.0898 9.32473L9.74468 8.74793V17.9962C10.0613 17.9405 10.3748 17.8592 10.6719 17.7598H10.6794L13.9315 16.671C15.4633 16.1582 16.4963 14.7199 16.4963 13.0995V9.66659L13.6119 10.6319C13.2938 10.7389 12.9705 10.7893 12.6516 10.7893Z"
       
      />
    </svg>
  )
}

export const PhoneIcon = ({ color = "#5f6368" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="#5f6368"><path d="M280-40q-33 0-56.5-23.5T200-120v-720q0-33 23.5-56.5T280-920h400q33 0 56.5 23.5T760-840v720q0 33-23.5 56.5T680-40H280Zm0-200v120h400v-120H280Zm200 100q17 0 28.5-11.5T520-180q0-17-11.5-28.5T480-220q-17 0-28.5 11.5T440-180q0 17 11.5 28.5T480-140ZM280-320h400v-400H280v400Zm0-480h400v-40H280v40Zm0 560v120-120Zm0-560v-40 40Z" /></svg>
);
export const EmailIcon = ({ color = "#5f6368" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480v58q0 59-40.5 100.5T740-280q-35 0-66-15t-52-43q-29 29-65.5 43.5T480-280q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480v58q0 26 17 44t43 18q26 0 43-18t17-44v-58q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93h200v80H480Zm0-280q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Z" /></svg>
);

export const ProductIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M680-80q-50 0-85-35t-35-85q0-6 3-28L282-392q-16 15-37 23.5t-45 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q24 0 45 8.5t37 23.5l281-164q-2-7-2.5-13.5T560-760q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-24 0-45-8.5T598-672L317-508q2 7 2.5 13.5t.5 14.5q0 8-.5 14.5T317-452l281 164q16-15 37-23.5t45-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T720-200q0-17-11.5-28.5T680-240q-17 0-28.5 11.5T640-200q0 17 11.5 28.5T680-160ZM200-440q17 0 28.5-11.5T240-480q0-17-11.5-28.5T200-520q-17 0-28.5 11.5T160-480q0 17 11.5 28.5T200-440Zm480-280q17 0 28.5-11.5T720-760q0-17-11.5-28.5T680-800q-17 0-28.5 11.5T640-760q0 17 11.5 28.5T680-720Zm0 520ZM200-480Zm480-280Z" /></svg>

);
export const QuestionIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M478-240q21 0 35.5-14.5T528-290q0-21-14.5-35.5T478-340q-21 0-35.5 14.5T428-290q0 21 14.5 35.5T478-240Zm-36-154h74q0-33 7.5-52t42.5-52q26-26 41-49.5t15-56.5q0-56-41-86t-97-30q-57 0-92.5 30T342-618l66 26q5-18 22.5-39t53.5-21q32 0 48 17.5t16 38.5q0 20-12 37.5T506-526q-44 39-54 59t-10 73Zm38 314q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" /></svg>

);
export const WhatsappIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="white" d="M19.05 4.91A9.82 9.82 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01m-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.26 8.26 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.18 8.18 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23m4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07s.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28"/></svg>
);
export const CartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="35px" viewBox="0 -960 960 960" width="35px" fill="#fff"><path d="m480-560-56-56 63-64H320v-80h167l-64-64 57-56 160 160-160 160ZM280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM40-800v-80h131l170 360h280l156-280h91L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68.5-39t-1.5-79l54-98-144-304H40Z" /></svg>
);
export const CampairIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m320-160-56-57 103-103H80v-80h287L264-503l56-57 200 200-200 200Zm320-240L440-600l200-200 56 57-103 103h287v80H593l103 103-56 57Z" /></svg>
);
export const HeartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" /></svg>
);
export const Threedote = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="118" height="12" viewBox="0 0 118 12" fill="none">
    <ellipse cx="6" cy="5.68421" rx="6" ry="5.68421" fill="#5F5F5F" />
    <ellipse cx="34" cy="5.68421" rx="6" ry="5.68421" fill="#5F5F5F" />
    <ellipse cx="112" cy="5.68421" rx="6" ry="5.68421" fill="#5F5F5F" />
    <rect x="56" width="34" height="11.3684" rx="5.68421" fill="#604BE8" />
  </svg>
);
export const CheckIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={color ?? "#5f6368"} ><path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" /></svg>
);
export const NextIcon = () => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24.5" cy="24.5" r="24.5" fill="#D9D9D9" />
    <path d="M22.2525 17.7729L27.0496 23.9902L22.0003 29.9389" stroke="black" strokeWidth="2" />
  </svg>

);
export const FacebookIcon = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33 19.0605C33 26.078 27.8737 31.8956 21.1817 32.9513V23.1522H24.4355L25.055 19.098H21.1817V16.4675C21.1817 15.3578 21.723 14.2775 23.4567 14.2775H25.2172V10.8256C25.2172 10.8256 23.6188 10.5514 22.0917 10.5514C18.902 10.5514 16.8183 12.493 16.8183 16.007V19.0968H13.2728V23.151H16.8183V32.9502C10.1275 31.8933 5 26.0768 5 19.0605C5 11.2955 11.2685 4.99976 19 4.99976C26.7315 4.99976 33 11.2943 33 19.0605Z" fill="white" />
  </svg>
);
export const InstagramIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg"  width="25" height="25" fill="#fff" className="bi bi-instagram" viewBox="0 0 16 16">
  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
</svg>
);
export const TwiterIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.1335 8.65336C25.1498 8.8991 25.1498 9.146 25.1498 9.39407C25.1498 16.9724 19.3702 25.7108 8.80483 25.7108V25.7061C5.684 25.7108 2.62733 24.8187 0 23.1358C0.453833 23.1905 0.91 23.2173 1.36733 23.2185C3.955 23.2208 6.468 22.3543 8.5015 20.7587C6.04333 20.7121 3.88733 19.1119 3.13483 16.7756C3.99583 16.941 4.8825 16.9072 5.72833 16.6778C3.04733 16.1374 1.12 13.786 1.12 11.056C1.12 11.0316 1.12 11.0071 1.12 10.9838C1.91917 11.4287 2.81283 11.6745 3.7275 11.7012C1.20283 10.0183 0.4235 6.66647 1.94833 4.04601C4.865 7.62847 9.16883 9.80636 13.7877 10.0381C13.3245 8.04658 13.958 5.95953 15.449 4.55845C17.7625 2.38638 21.4013 2.49819 23.5772 4.80769C24.864 4.55496 26.0972 4.08328 27.2253 3.41593C26.796 4.74363 25.8988 5.87101 24.6995 6.58844C25.8393 6.45217 26.9512 6.1482 28 5.68467C27.2288 6.83651 26.2582 7.8416 25.1335 8.65336Z" fill="white" />
  </svg>



);
export const LinkinIcon = () => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.1728 27.1381H23.6647V21.6538C23.6647 20.3459 23.6413 18.663 21.84 18.663C20.013 18.663 19.733 20.0873 19.733 21.5594V27.1381H16.226V15.8596H19.5942V17.4005H19.6408C20.328 16.2288 21.6043 15.5289 22.9635 15.579C26.5195 15.579 27.174 17.9141 27.174 20.9503L27.1728 27.1381ZM12.2675 14.3176C11.1428 14.3176 10.2317 13.4081 10.2317 12.2853C10.2317 11.1626 11.1428 10.253 12.2675 10.253C13.3922 10.253 14.3033 11.1626 14.3033 12.2853C14.3033 13.4081 13.3922 14.3176 12.2675 14.3176ZM14.021 27.1381H10.5093V15.8596H14.021V27.1381ZM28.9217 7.00132H8.7465C7.79333 6.99084 7.01167 7.75369 7 8.70521V28.9282C7.01167 29.8809 7.79333 30.6437 8.7465 30.6332H28.9217C29.8772 30.6449 30.6623 29.882 30.6752 28.9282V8.70404C30.6612 7.75019 29.876 6.98735 28.9217 7.00016" fill="white" />
  </svg>

);
export const AcountIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="41" viewBox="0 0 36 41" fill="none">
    <path d="M2.11563 15.4999C0.974338 15.4999 0.0879001 14.5699 0.289195 13.5566C0.952177 10.2216 2.79338 7.12154 5.56165 4.77987C9.13511 1.75485 13.8018 0.246516 18.7216 0.53485C27.9941 1.07485 35.237 8.35988 35.1003 17.0632C35.0265 21.7599 30.6885 25.5016 25.4825 25.5016H20.0678C19.403 26.0783 18.5055 26.4366 17.5119 26.4366C15.4713 26.4366 13.8185 24.9449 13.8185 23.1033C13.8185 21.2616 15.4713 19.7699 17.5119 19.7699C19.1888 19.7699 20.5886 20.7849 21.0392 22.1683H25.4825C28.6884 22.1683 31.3718 19.8633 31.4069 16.9716C31.4918 10.0616 25.7835 4.28986 18.4833 3.8632C14.607 3.64653 10.9098 4.82653 8.08615 7.21488C5.85344 9.10322 4.41482 11.5216 3.91066 14.1782C3.76292 14.9549 2.98729 15.5032 2.11563 15.5032V15.4999ZM17.5581 28.8333C10.7344 28.8333 4.83034 32.6533 2.86725 38.3367C2.69181 38.8433 2.79338 39.395 3.14242 39.8217C3.49145 40.2483 4.04363 40.5 4.63089 40.5H30.4853C31.0726 40.5 31.6248 40.2483 31.9738 39.8217C32.3228 39.395 32.4244 38.8433 32.249 38.3367C30.2859 32.6533 24.3818 28.8333 17.5581 28.8333ZM22.9765 18.8333H27.3237C27.573 18.0382 27.7152 17.2016 27.7152 16.3332C27.7152 11.2699 23.1685 7.16654 17.5581 7.16654C11.9477 7.16654 7.40101 11.2699 7.40101 16.3332C7.40101 18.8183 8.50167 21.0683 10.2801 22.7199C10.5035 19.2766 13.6393 16.5382 17.5119 16.5382C19.7114 16.5382 21.6505 17.4399 22.9783 18.8333H22.9765Z" fill="#604BE8" />
  </svg>

);