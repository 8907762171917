import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CustomerCarsouel.css';
import { BackIcon, NextIcon } from '../../../../component/icon';
import icon1 from '../../../../asesst/silder-1.png';
import icon2 from '../../../../asesst/slider-2.png';
import icon3 from '../../../../asesst/slider-3.png';
import icon4 from '../../../../asesst/slider-4.png';
import icon5 from '../../../../asesst/slider-5.png';
import icon6 from '../../../../asesst/slider-6.png';
import icon7 from '../../../../asesst/slider-7.png';
import icon8 from '../../../../asesst/slider-8.png';
import icon9 from '../../../../asesst/slider-9.png';
import icon10 from '../../../../asesst/slider-10.png';

const CustomCarousel = () => {
  const [sliderRef, setSliderRef] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false, // Hides the default arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const items = [
    { icon: icon1 },
    { icon: icon2 },
    { icon: icon3 },
    { icon: icon4 },
    { icon: icon5 },
    { icon: icon6 },
    { icon: icon7 },
    { icon: icon8 },
    { icon: icon9 },
    { icon: icon10 },
  ];

  return (
    <div className="main_box">
      <button className="slider-button prev_btn" onClick={() => sliderRef.slickNext()} >
        <BackIcon />
      </button>
      <div style={{ width: '80%', margin: 'auto' }}>
        <Slider ref={setSliderRef} {...settings}>
          {items.map((item, index) => (
            <div key={index} className="card_style">
              <div className="box_style">
                <img src={item.icon} alt={`icon-${index}`} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <button className="slider-button next_btn" onClick={() => sliderRef.slickPrev()} >
        <NextIcon />
      </button>
    </div>
  );
};

export default CustomCarousel;
