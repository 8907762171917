import React from 'react';
import CustomNavbar from '../../Component/Navber';
import LetsStart from '../../Component/LetsStart';
import Footer from '../../Component/Footer';
import './style.css'
import PricingPlanCard from '../../../../component/Pricingplan';

function PricingPlans() {


    const offlinePlanFeatures = [
        { title: 'Dashboard', },
        { title: 'Configuration', },
        { title: 'Items', },
        { title: 'Inventory Management', },
        { title: 'Purchase', },
        { title: 'Parties', },
        { title: 'Accounts', },
        { title: 'Sales', },
        { title: 'Backup', },
    ];

    const onlineOfflinePlanFeatures = [
        { title: 'All Basic Modules', },
        { title: 'HR Configuration', },
        { title: 'E-commerce Integration', },
        { title: 'User Permissions', },
        { title: 'Auto Syncing with Cloud Database', },
        { title: 'Use Anywhere', },
        { title: 'sub Domain for E-commerce Store', },
        { title: 'One-Click Store Generation', },
        { title: 'Online Inventory & Quick Order Processing', },
        { title: 'And Much More', },
    ];
    return (
        <>
            <CustomNavbar />
            <div className='about__sub'>
                <div className='w-100'>
                    <h1 className='about_heading text-white' >Pricing Plans </h1>
                    <a href='/' className='home_link'>Home</a>   <span className='slash_tyle'>/</span>  <a className='about_link' href='/pricing-Plans'>Pricing Plans </a>
                    {/* Welcome to Tijarah Soft, where innovation meets simplicity in business management. We are a dynamic team of professionals dedicated to empowering businesses with the tools they need to thrive in today's competitive landscape. Our mission is to simplify business operations through cutting-edge software solutions that are easy to use, reliable, and tailored to meet the unique needs of our customers. */}
                </div>
            </div>
            <div className='pricing_text_box'>
                <div>
                    {/* <p className='pricing_content'>
                        At Tijarah Soft, we offer flexible pricing plans tailored to your business needs. Whether you operate entirely offline or need the versatility of both online and offline capabilities, we've got you covered.
                    </p> */}
                </div>

            </div>

            <div className='pricing_content_container'>
                <div>
                    <PricingPlanCard
                        planTitle="Basic Plan"
                        originalPrice={1999}
                        discountedPrice={299}
                        // planDescription="Our Offline Modules Plan provides essential tools for managing your business effectively."
                        features={offlinePlanFeatures}
                        additionalInfo="This plan is perfect for businesses that need robust offline management without the complexity of online integration."
                    />

                </div>
                <div>
                    <PricingPlanCard
                        planTitle="Pro Plan"
                        originalPrice={5999}
                        discountedPrice={999}
                        // planDescription="Upgrade to our Online & Offline Modules Plan to unlock the full potential of Tijarah Soft. This plan includes everything in the Offline Modules Plan, plus:"
                        features={onlineOfflinePlanFeatures}
                        additionalInfo="This plan is ideal for businesses looking to grow their online presence while maintaining full control over their offline operations."
                    />
                </div>
            </div>
            <LetsStart />
            <Footer />
        </>
    )
}

export default PricingPlans;