export const fetchDomain = () => {
  const hostname = window.location.hostname;
  // const hostname = "hashimfood";
  // const hostname = "novamedicare";
  // const hostname = "flavourheaven";

  if (hostname.split(".")[1] === "tijarahsoft") {
    return hostname.split(".")[0];
  } else {
    return hostname;
  }
};
export function createSlug(title) {
  return title
    ?.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
}

export function decodeSlug(slug) {
  return slug.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
}

export function generateWhatsAppUrl(
  phoneNumber,
  customerName,
  totalAmount,
  shippingAddress,
  mobile,
  city,
  shippingCharges,
  products
) {
  let message = `Order Details:\n\nCustomer Name: ${customerName}\nMobile Number: ${mobile}\nTotal Amount: ${totalAmount}\nShipping Charges: ${shippingCharges}\nCity: ${city}\nShipping Address: ${shippingAddress}\n\nProducts:\n`;

  products.forEach((product, index) => {
    message += `${index + 1}. Product Name: ${product.name}\n   Quantity: ${product.qty
      }\n   Price: ${product.amount}\n   Variant: ${product.variant ?? "-"}\n`;
  });

  message += `\n\nThank you for your order!`;

  const encodedMessage = encodeURIComponent(message);

  const whatsappUrl = `https://api.whatsapp.com/send?phone=+${phoneNumber}&text=${encodedMessage}`;

  return whatsappUrl;
}
