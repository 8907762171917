import React from 'react'
import './style.css'
import { FacebookIcon, InstagramIcon, LinkinIcon, TwiterIcon } from '../../../../component/icon';
import Logo from '../../../../asesst/logo.png'
import { Button } from 'react-bootstrap';

function Footer() {
    // const handleButtonClick = () => {
    //     // Yahan 'certificate.pdf' aapki PDF file ka naam ya URL hoga
    //     window.open('./tijarahntn.pdf', '_blank');
    // };
    return (
        <div className='main_footer'>
            <div className='compelet__screen_perfect main_sec_footer'>
                <div className='footer_flex '>
                    <div className=' d-flex justify-content-center  icon_sett'>
                        <div>
                            <img src={Logo} alt='Tijarah Soft' className='home_logo' />
                        </div>
                        <div className='fotter__icons'>
                            <div>
                                <a href='https://www.facebook.com/tijarahsoft' target='_blank'>
                                    <FacebookIcon />
                                </a>
                            </div>
                            <div>
                                <a href='https://www.linkedin.com/company/tijarah-soft/' target='_blank'>
                                    <LinkinIcon />
                                </a>
                            </div>
                            <div>
                                <a href='https://www.instagram.com/tijarahsoft/' target='_blank'>
                                    <InstagramIcon />
                                </a>
                            </div>
                        </div>

                    </div>
                    <div>
                        <ul className=' links_container use_containe'  >
                            <h4 className='container_title'>Navigation</h4>
                            <li className='links_item'><a href='/'>Home</a></li>
                            <li className='links_item'><a href='/about-us'>About Us</a></li>
                            <li className='links_item'><a href='/features'>Features</a></li>
                            <li className='links_item'><a href='/pricing-plans'>Pricing Plans</a></li>
                        </ul>
                    </div>
                    <div>
                        <ul className='links_container use_containe'>
                            <h4 className='container_title'>Useful Links</h4>
                            <li className='links_item'><a href='/service-policy'>Service Policy</a></li>
                            <li className='links_item'><a href='/refund-policy'>Refund Policy</a></li>
                            <li className='links_item'><a href='/privacy-policy'>Privacy Policy</a></li>
                            <li className='links_item'><a href='/terms-and-conditions'>Terms and Conditions</a></li>
                        </ul>
                    </div>
                    <div>
                        <ul className='links_container  conatct_contaoner'>
                            <h4 className='container_title'>Contact Us</h4>
                            <li className='links_item'><a href=''>+92 342 4652710</a></li>
                            <li className='links_item'><a href=''>+92 370 3271825</a></li>
                            <li className='links_item'><a href=''>support@tijarahsoft.com</a></li>
                            <li className='links_item'><a href=''>N-A Zaman Town Korangi  Karachi Pakistan</a></li>
                        </ul>
                    </div>
                    <div>
                        <ul className='links_container'>
                            <h4 className='container_title'>NTN</h4>
                            <li className='links_item'><a href=''>G019780</a></li>

                            <Button
                                className='certificate_btn'
                                onClick={() => window.open('./Tijarah Soft NTN.pdf', '_blank')}
                            >
                                Get Certificate
                            </Button>

                        </ul>
                    </div>



                </div>
                <p className='  last_text_footer'>© 2024 .Tijarah Soft</p>
            </div>
        </div>
    )
}

export default Footer;
