import React, { useContext, useEffect, useMemo, useState } from "react";
import { ContentContext } from "../../../../store";
import { fetchCategories } from "../../../../apis/categories";
import { createSlug } from "../../../../helper";
import Placeholder from "../../../../asesst/placeholder.jpeg";
import "./style.css";
function Header({ domain }) {
  const { domainInfo, products, isModalOpen, storeName } =
    useContext(ContentContext);
  const [categoryList, setcategoryList] = useState("");

  useEffect(() => {
    const fetch = async () => {
      try {
        if (domainInfo?.css) {
          const styleElement = document.createElement("style");
          styleElement.textContent = domainInfo.css;
          document.head.appendChild(styleElement);
    
          return () => {
            document.head.removeChild(styleElement);
          };
        }
        if (domainInfo?.domain) {
          const response = await fetchCategories(domainInfo?.domain);
          if (response) {
            setcategoryList(
              response
                .map(
                  (i) => `
              <a class="mega-heading unselectable-block" href='/category/${createSlug(
                i.category
              )}'><li class="unselectable-block">${i.category}</li></a
              >
          `
                )
                .join("")
            );
          }
        }
      } catch (error) {}
    };

    fetch();
  }, [domainInfo]);
 
  useEffect(() => {
    const updateCountBox = () => {
      const storedProducts = localStorage.getItem(`${domain}-carts`);
      const parsedProducts = storedProducts ? JSON.parse(storedProducts) : [];
      const countBoxElements = document.querySelectorAll(
        '[data-id="count-box"]'
      );

      countBoxElements.forEach((element) => {
        element.innerHTML = parsedProducts.length; // Update the count
      });
      const categoriesElement = document.querySelectorAll(
        '[data-id="header-category"]'
      );
      categoriesElement.forEach((element) => {
        element.innerHTML = categoryList;
      });
    };

    updateCountBox();
  }, [domainInfo?.header, domain, isModalOpen]);

  useEffect(() => {
    const categoriesElement = document.querySelectorAll(
      '[data-id="header-category"]'
    );
    categoriesElement.forEach((element) => {
      element.innerHTML = categoryList;
    });
  }, [categoryList, domainInfo]);
  useEffect(() => {
    const openIcon = document.getElementById("open-icon");
    const closeIcon = document.getElementById("close-icon");
    const toggleIcon = document.getElementById("toggle-icon");

    const handleOpenClick = () => {
      const expandContainer = document.getElementById("expand-container");
      const searchSection = document.getElementById("search_section");
      if (expandContainer && searchSection) {
        expandContainer.classList.add("d-expand");
        searchSection.classList.add("d-expand");
      }
    };

    const handleCloseClick = () => {
      const expandContainer = document.getElementById("expand-container");
      const searchSection = document.getElementById("search_section");
      if (expandContainer && searchSection) {
        expandContainer.classList.remove("d-expand");
        searchSection.classList.remove("d-expand");
      }
    };

    const handleToggleClick = () => {
      const backdrop = document.getElementById("backdrop");
      const sideBar = document.getElementById("side-bar");
      if (backdrop && sideBar) {
        backdrop.classList.toggle("backdrop");
        sideBar.classList.toggle("show-menu");
        sideBar.classList.remove("hide-menu");
      }
    };

    const handleCloseMenuClick = () => {
      const backdrop = document.getElementById("backdrop");

      const sideBar = document.getElementById("side-bar");
      if (backdrop && sideBar) {
        backdrop.classList.remove("backdrop");
        sideBar.classList.remove("show-menu");
        sideBar.classList.add("hide-menu");
      }
    };

    if (openIcon) {
      openIcon.addEventListener("click", handleOpenClick);
    }

    if (closeIcon) {
      closeIcon.addEventListener("click", handleCloseClick);
    }

    if (toggleIcon) {
      toggleIcon.addEventListener("click", handleToggleClick);
    }

    const closeMenuButtons = document.querySelectorAll(".close-menu");
    closeMenuButtons.forEach((button) => {
      button.addEventListener("click", handleCloseMenuClick);
    });

    return () => {
      if (openIcon) openIcon.removeEventListener("click", handleOpenClick);
      if (closeIcon) closeIcon.removeEventListener("click", handleCloseClick);
      if (toggleIcon)
        toggleIcon.removeEventListener("click", handleToggleClick);
      closeMenuButtons.forEach((button) => {
        button.removeEventListener("click", handleCloseMenuClick);
      });
    };
  }, [domainInfo.header]);
  const htmlString = domainInfo?.header || "";
  useEffect(() => {
    const searchInputs = document.querySelectorAll('[data-id="search-input"]');
    const searchResults = document.querySelectorAll(
      '[data-id="search-result"]'
    );
    

    function renderList(filteredItems, index) {
      if (!searchResults[index]) {
        console.error(`No search result container found for index ${index}`);
        return;
      }

      const resultElement = searchResults[index];
      resultElement.innerHTML = "";

      if (filteredItems.length === 0) {
        resultElement.innerHTML = "<p>No results found</p>";
        return;
      }

      filteredItems.forEach((item) => {
        const itemHTML = `
<div class="mb-2">
          <a class='search-item' href="/product/${createSlug(item.itemname)}">
            <div class="d-flex gap-2 align-items-center">
              <img
                src="${item.image || Placeholder}"
                alt="${item.itemname}"
                class="img"
              />
              <p class="item-name mb-0">${item.itemname}</p>
              <p class="price mb-0">
                <span class="rs-bold">Rs.</span>${item.retailPrice || "N/A"}
              </p>
            </div>
          </a>
          </div>
        `;
        resultElement.innerHTML += itemHTML;
      });
    }

    const handleInput = (event, index) => {
      const query = event.target.value.toLowerCase();
      const filteredList = products?.filter((item) =>
        item.itemname.toLowerCase().includes(query)
      );
      renderList(filteredList, index);
      searchResults[index].style.display = "block"; // Show the result container
    };

    const handleOutsideClick = (event) => {
      searchResults.forEach((result) => {
        if (!result.contains(event.target)) {
          result.style.display = "none"; // Hide result container on outside click
        }
      });
    };

    searchInputs.forEach((input, index) => {
      input.addEventListener("input", (event) => handleInput(event, index));
    });

    document.addEventListener("click", handleOutsideClick);
    searchResults.forEach((_, index) => renderList(products, index));

    return () => {
      searchInputs.forEach((input, index) => {
        input.removeEventListener("input", (event) =>
          handleInput(event, index)
        );
      });
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [products,htmlString]);
  const processedHtmlString = useMemo(() => {
    const rawHtml = htmlString?.replaceAll("[store_name]", storeName);
    return rawHtml;
  }, [htmlString,products]);

  if (!domainInfo || !domainInfo.header) {
    return null;
  }

  return <div dangerouslySetInnerHTML={{ __html: processedHtmlString }} />;
}

export default Header;
