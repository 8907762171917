import React from "react";

function PageNotFound({ error, name }) {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <h1>
          {" "}
          <span style={{ fontSize: "45px", fontWeight: "800" }}>
            {error || 404}
          </span>{" "}
          {name || "Page "} Not Found
        </h1>
      </div>
    </div>
  );
}

export default PageNotFound;
