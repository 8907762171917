import React, { useEffect, useState } from "react";
import BestProductCard from "../Cards/BestProductCard";
import { motion, useInView } from "framer-motion"; // Import useInView
import { useNavigate } from "react-router-dom";
import { createSlug } from "../../../../helper";
import "./style.css";
function BestProduct({
  products,
  domain,
  initialVisible = 8,
  heading,
  loader,
}) {
  const [visibleProducts, setVisibleProducts] = useState(initialVisible);
  const navigate = useNavigate();
  // const [loader, setLoader] = useState(false);

  // // Loader effect
  // useEffect(() => {
  //   setLoader(true);
  //   setTimeout(() => {
  //     setLoader(false);
  //   }, 4000);
  // }, []);

  const loadMoreProducts = () => {
    setVisibleProducts((prevCount) => prevCount + 4);
  };

  // Ref for heading visibility
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true }); // Trigger animation once

  // Animation variants for the heading
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="screen-spacing hdt-pr-discount2 px-lg-5 best-product-container text-center my-md-3">
      {products !== "No products found." && (
        <>
          {heading && (
            <div
              ref={ref} // Attach the ref to the heading container
              className="hdt-section-main-heading hdt-flex hdt-justify-center hdt-align-center hdt-text-center hdt-relative"
            >
              {/* <motion.h3
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={variants}
                transition={{ duration: 0.5 }}
                // className="hdt-sec-heading hdt-h4 hdt-flex hdt-align-center"
              >
                {heading}
              </motion.h3> */}
              <h3>{heading}</h3>
            </div>
          )}
          <div className="slider-track card-content product-grid">
            {products !== "No products found." &&
              products?.slice(0, visibleProducts)?.map((product, index) => {
                const price =
                  product?.itemType === "Variants"
                    ? Math.min(
                        ...product?.combinations?.map(
                          (combination) => Number(combination?.retailPrice) || 0
                        )
                      )
                    : Number(product?.retailPrice);
                return (
                  <BestProductCard
                    key={index}
                    src={product?.image}
                    link={`/product/${createSlug(product?.itemname)}`}
                    title={product?.itemname}
                    domain={domain}
                    product={product}
                    price={price?.toFixed()}
                    onClick={() => navigate("/checkout")}
                    className="best-Product-card"
                  />
                );
              })}
          </div>
        </>
      )}
     {!products && "loading"}
     
      {products === "No products found." && (
        <div style={{ fontSize: "35px", fontWeight: "bold" }}>
          Product Not Found
        </div>
      )}

      {visibleProducts < products?.length && (
        <button
          onClick={loadMoreProducts}
          className="hdt-dark-btn hdt-align-center hdt-btn hdt-button hdt-font-semibold my-5"
        >
          Load More Products
        </button>
      )}
    </div>
  );
}

export default BestProduct;
