import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContentContext } from "../../../../store";
import AnnouncementBar from "../../Component/AnnouncementBar";
import BestProduct from "../../Component/BestProduct";
import Footer from "../../Component/Footer";
import Navbar from "../../Component/Navbar";
import OpenCart from "../../Component/OpenCart";
import Banner from "../Cart/Component/Banner";
import {
  fetchCategories,
  fetchCategoriesProducts,
} from "../../../../apis/categories";
import "./style.css";
import { createSlug, decodeSlug } from "../../../../helper";
import WhatsappBtn from "../../Component/WhatsappBtn";
function Category({ domain }) {
  const { domainInfo } = useContext(ContentContext);
  const { title } = useParams();
  const name = decodeSlug(title);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState(true);
  

  useEffect(() => {
    const fetch = async () => {
      setLoader(true);
      try {
        if (domainInfo?.domain) {
          const response = await fetchCategories(domainInfo?.domain);
          if (response) {
            setCategories(response);
          }

          const categoriesProducts = await fetchCategoriesProducts(
            domainInfo?.domain,
            name
          );
          setLoader(false);
          setProducts(categoriesProducts);
          setFilteredProducts(categoriesProducts);
        }
      } catch (error) {
      } finally {
        setLoader(false);
      }
    };
    fetch();
  }, [domainInfo]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
    if (value === "") {
      setFilteredProducts(products);
    } else {
      let filtered = products.filter((product) =>
        product.itemname.toLowerCase().includes(value?.trim())
      );
      filtered = filtered?.map((product) => {
        const price =
          product?.itemType === "Variants"
            ? Math.min(
                ...product?.combinations?.map(
                  (combination) => Number(combination?.retailPrice) || 0
                )
              )
            : Number(product?.retailPrice);
        return {
          ...product,
          retailPrice: price,
        };
      });
      setFilteredProducts(filtered);
    }
  };

  useEffect(() => {
    if (domainInfo?.tawkTo) {
      // Parse and insert the script into the document
      const scriptTag = document.createElement("div");
      scriptTag.innerHTML = domainInfo.tawkTo; // Assuming `tawkTo` contains the HTML with the script
      const scriptElement = scriptTag.querySelector("script");

      // Execute the script if available
      if (scriptElement) {
        const newScript = document.createElement("script");
        newScript.type = "text/javascript";
        newScript.async = true;
        newScript.innerHTML = scriptElement.innerHTML; // Set script content
        document.body.appendChild(newScript);

        // Clean up the script when component unmounts
        return () => {
          document.body.removeChild(newScript);
        };
      }
    }
  }, [domainInfo?.tawkTo]);

  return (
    <div className="category-page">
      {domainInfo?.topNavbar && (
        <AnnouncementBar
          note={domainInfo?.topNavbarNote}
          mobile={domainInfo?.topNavbarMobile}
          email={domainInfo?.topNavbarEmail}
        />
      )}
      <Navbar domain={domain} logo={domainInfo?.logo} />
      <Banner lable={name} />
      <div className="shop__main__box">
        <div className="show__hero_box">
          <div className="container">
           
              <BestProduct
                initialVisible={18}
                products={filteredProducts}
                domain={domain}
                loader={loader}
              />
            
          </div>
        </div>
        <div className="sidebar_box px-4 mt-5" style={{}}>
          <div className="serach_bar_box">
            <input
              type="text"
              className="seaech_inp"
              placeholder="Search"
              value={searchValue}
              onChange={handleSearch}
            />
          </div>

          <div className="serach_bar_box pt-5 ">
            <h3 style={{ fontSize: 18, fontWeight: "900" }}>Categories</h3>
            {categories?.map((e, i) => (
              <div className="d-flex  flex-row ">
                <a
                  href={`/category/${createSlug(e?.category)}`}
                  style={{ opacity: 0.7, fontSize: 14, paddingTop: 10 }}
                >
                  {" "}
                  {`${e?.category} - ${e?.count}`}
                </a>
              </div>
            ))}
          </div>
          <div className="serach_bar_box pt-5 ">
            <h3 style={{ fontSize: 18, fontWeight: "900" }}>Filter By Price</h3>
            <div className="d-flex gap-5 pt-2">
              <div>
                <input className="price_inp" placeholder="max-price" />
              </div>
              <div>
                <input className="price_inp" placeholder="min-price" />
              </div>
            </div>
            {/* <Slider /> */}
          </div>
        </div>
      </div>
      <Footer
        credit={domainInfo?.footerCredit}
        logo={domainInfo?.footerLogo}
        socialMedia={domainInfo?.socialMedia}
        isSocialMedia={domainInfo?.isSocialMedia}
      />
      <OpenCart domain={domain} initialProducts={products} />
      {domainInfo?.isWhatsappMessageAllowed && domainInfo?.whatsapp && (
        <WhatsappBtn number={domainInfo?.whatsapp} />
      )}
    </div>
  );
}

export default Category;
