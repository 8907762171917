import { Box } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import Features from "./Component/Features";
import AnnouncementBar from "./Component/AnnouncementBar";
import Navbar from "./Component/Navbar";
import HeroSection from "./Component/HeroSection";
import BestProduct from "./Component/BestProduct";
import WhatsappBtn from "./Component/WhatsappBtn";
import Footer from "./Component/Footer";
import "./store.css";
import { ContentContext } from "../../store";
import { createVisitor } from "../../apis/views";
import { createSlug, fetchDomain } from "../../helper";
import MetaTags from "react-meta-tags";
import OpenCart from "./Component/OpenCart";
import Loader from "../../asesst/loader.gif";
import HtmlToReact from "html-to-react";
import Placeholder from "../../asesst/placeholder.jpeg";
import { fetchCategories } from "../../apis/categories";
import { fetchProducts } from "../../apis/products";
import ProductCard from "./Component/Cards/ProductCard";
function Home({ domain }) {
  const {
    products,
    categories,
    domainInfo,
    domianFetchErr,
    domainFetchStatus,
    storeName,
    setIsModalOpen,
    isModalOpen,
    setselectedItems,
  } = useContext(ContentContext);

  const createStoreVisitor = async () => {
    try {
      await createVisitor({ domain: fetchDomain() });
    } catch (error) {}
  };

  useEffect(() => {
    createStoreVisitor();
  }, []);
  useEffect(() => {
    if (domainInfo?.tawkTo) {
      const scriptTag = document.createElement("div");
      scriptTag.innerHTML = domainInfo.tawkTo;
      const scriptElement = scriptTag.querySelector("script");

      if (scriptElement) {
        const newScript = document.createElement("script");
        newScript.type = "text/javascript";
        newScript.async = true;
        newScript.innerHTML = scriptElement.innerHTML;
        document.body.appendChild(newScript);

        return () => {
          document.body.removeChild(newScript);
        };
      }
    }
  }, [domainInfo?.tawkTo]);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if (domainInfo || domianFetchErr) {
      setLoader(false);
    }
  }, [domianFetchErr, domainInfo]);

  const [reactElement, setReactElement] = useState(null);
  const [categoriesSlider, setCategoriesSlider] = useState([]);
  const [item, setItem] = useState("");
  const [categoryItems, setCategoryItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [categoryList, setcategoryList] = useState("");

  const handleAddCart = (itemId, itemName, retailPrice, variantName, image) => {
    
    const storedProducts = localStorage.getItem(`${domain}-carts`);
    let productsList = storedProducts ? JSON.parse(storedProducts) : [];

    setIsModalOpen(true);

    let newProductsList;
    const isProductWithVariant = productsList?.some(
      (item) => item.id === itemId && item.selectedVariant === variantName
    );

    if (isProductWithVariant) {
      newProductsList = productsList.map((item) => {
        if (item.id === itemId && item.selectedVariant === variantName) {
          return { ...item, selectedQty: item.selectedQty + 1 };
        }
        return item;
      });
    } else {
      const isId = productsList?.some((item) => item.id === itemId);
      if (isId && !variantName) {
        newProductsList = productsList.map((item) => {
          if (item.id === itemId) {
            return { ...item, selectedQty: item.selectedQty +  1 };
          }
          return item;
        });
      } else {
        newProductsList = [
          {
            id: itemId,
            itemname: itemName,
            selectedVariant: variantName,
            retailPrice,
            selectedQty:1,
            image,
          },
          ...productsList,
        ];
      }
    }
    const countBox = document.querySelectorAll('[data-id="count-box"]');
    countBox.forEach((element) => {
      element.innerHTML = newProductsList.length;
    });
    setselectedItems(newProductsList);
    localStorage.setItem(`${domain}-carts`, JSON.stringify(newProductsList));
  };
  
  useEffect(() => {
    const storedProducts = localStorage.getItem(`${domain}-carts`);
    if (storedProducts) {
      const parsedProducts = JSON.parse(storedProducts);
      setCartCount(parsedProducts.length);
    }
  }, []);

  useEffect(() => {
    if (products !== "No products found.") {
      const convertedString = products?.map((product) =>
        ProductCard(product, createSlug, Placeholder)
      );
      setItem(convertedString);
    }
  }, [categoriesSlider]);

  useEffect(() => {
    if (domainInfo?.domain) {
      if (products.length > 0) {
        fetchCategories(domainInfo?.domain).then((response) => {
          const categoryItem = response.map((i) => {
            const id = i.id;

            const items = products
              ?.filter((item) => item.categoryId === i.id)
              ?.map((item) => ProductCard(item, createSlug, Placeholder))
              .join("");

            return { id, items };
          });

          setCategoryItems(categoryItem);
        });
      } else {
        setCategoryItems("");
      }
    }
  }, [products, domainInfo]);

  useEffect(() => {
    const fetch = async () => {
      try {
        if (domainInfo?.domain) {
          const response = await fetchCategories(domainInfo?.domain);
          if (response) {
            const content = response?.map(
              (i) => `<div class="card-slider-item">
                  <div class="category-card">
                    <div class="card-media media--hover-effect">
                      <img
                        src=${i.image ? i.image : Placeholder}
                        sizes="(min-width: 1100px) 960px, (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)"
                      />
          
                      <a
                        class="card-link"
                        href="/category/${createSlug(i.category)}"
                        
                      ></a>
             
                    </div>
                    <div class="position-relative">
                      <h3 class="card__heading text-center">
                        <a
                          class="card-title link-underline card-title-ellipsis card-title-change"
                          href="/product/${createSlug(i.itemname)}"
                         
                          tabindex="0"
                          >${i.category}
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
                `
            );

            setCategoriesSlider(content);
            setcategoryList(
              response
                .map(
                  (i) => `
              <a class="mega-heading unselectable-block" href='/category/${createSlug(
                i.category
              )}'><li class="unselectable-block">${i.category}</li></a
              >
            
          `
                )
                .join("")
            );
          }
        }
      } catch (error) {}
    };

    fetch();
  }, [domainInfo]);

  useEffect(() => {
    const data = storeName;
    const htmlString = domainInfo?.html;
    const cssString = domainInfo?.css;

    if (htmlString && categoriesSlider && item &&categoryItems) {
      const processedHtmlString = htmlString?.replaceAll("[store_name]", data);
      const parser = new DOMParser();
      const doc = parser.parseFromString(processedHtmlString, "text/html");

      try {
        const categorySliderElements = doc.querySelectorAll(
          '[data-id="category-slider"]'
        );

        categorySliderElements.forEach((element) => {
          element.innerHTML = categoriesSlider;
        });
        const allProductCardElements = doc.querySelectorAll(
          '[data-id="all-product-card1-slider"]'
        );

        if (item) {
          allProductCardElements.forEach((element) => {
            element.innerHTML = item;
          });
        }

        const updateSliderContent = (id, item) => {
          const element = doc.querySelectorAll(`[data-id='${id}']`);

          element.forEach((element) => {
            element.innerHTML = item;
          });
        };
        categoryItems?.forEach((i) => updateSliderContent(i.id, i.items));
        const countBoxElement = doc.querySelectorAll('[data-id="count-box"]');
        countBoxElement.forEach((element) => {
          element.innerHTML = cartCount;
        });
        const categoriesElement = doc.querySelectorAll(
          '[data-id="header-category"]'
        );
        categoriesElement.forEach((element) => {
          element.innerHTML = categoryList;
        });
        if (cssString) {
          const styleElement = document.createElement("style");
          styleElement.textContent = cssString;
          document.head.appendChild(styleElement);
        }

        const updatedHtmlString = doc.body.innerHTML;
        const htmlToReactParser = new HtmlToReact.Parser();
        const parsedElement = htmlToReactParser.parse(updatedHtmlString);

        setReactElement(parsedElement);
      } catch (error) {
        console.error("Error during HTML processing:", error);
      }
    } else {
      console.log("No HTML found");
    }
  }, [categoriesSlider, item, categoryItems]);

  useEffect(() => {
    
    const openIcon = document.getElementById("open-icon");
    const closeIcon = document.getElementById("close-icon");
    const toggleIcon = document.getElementById("toggle-icon");

    const handleOpenClick = () => {
      const expandContainer = document.getElementById("expand-container");
      const searchSection = document.getElementById("search_section");
      if (expandContainer && searchSection) {
        expandContainer.classList.add("d-expand");
        searchSection.classList.add("d-expand");
      }
    };

    const handleCloseClick = () => {
      const expandContainer = document.getElementById("expand-container");
      const searchSection = document.getElementById("search_section");
      if (expandContainer && searchSection) {
        expandContainer.classList.remove("d-expand");
        searchSection.classList.remove("d-expand");
      }
    };

    const handleToggleClick = () => {
      const backdrop = document.getElementById("backdrop");
      const sideBar = document.getElementById("side-bar");
      if (backdrop && sideBar) {
        backdrop.classList.toggle("backdrop");
        sideBar.classList.toggle("show-menu");
        sideBar.classList.remove("hide-menu");
      }
    };

    const handleCloseMenuClick = () => {
      const backdrop = document.getElementById("backdrop");
      const sideBar = document.getElementById("side-bar");
      if (backdrop && sideBar) {
        backdrop.classList.remove("backdrop");
        sideBar.classList.remove("show-menu");
        sideBar.classList.add("hide-menu");
      }
    };

    if (openIcon) {
      openIcon.addEventListener("click", handleOpenClick);
    }

    if (closeIcon) {
      closeIcon.addEventListener("click", handleCloseClick);
    }

    if (toggleIcon) {
      toggleIcon.addEventListener("click", handleToggleClick);
    }

    const closeMenuButtons = document.querySelectorAll(".close-menu");
    closeMenuButtons.forEach((button) => {
      button.addEventListener("click", handleCloseMenuClick);
    });
    return () => {
      if (openIcon) openIcon.removeEventListener("click", handleOpenClick);
      if (closeIcon) closeIcon.removeEventListener("click", handleCloseClick);
      if (toggleIcon)
        toggleIcon.removeEventListener("click", handleToggleClick);
      closeMenuButtons.forEach((button) => {
        button.removeEventListener("click", handleCloseMenuClick);
      });
    };
  }, [reactElement]);
  useEffect(() => {
    const buttons = document.querySelectorAll(".button-ATC");
  
 
    const handleButtonClick = (event) => {
      const button = event.currentTarget;
      const itemId = button.getAttribute("itemid");
      const itemName = button.getAttribute("item-name").replaceAll("-", " ");
      const retailPrice = button.getAttribute("retail-price");
      const variantName = button.getAttribute("variant");
      const image = button.getAttribute("image-src");
  
      handleAddCart(itemId, itemName, retailPrice, variantName, image);
    };
  
    // Attach event listeners
    buttons.forEach((button) => {
      button.addEventListener("click", handleButtonClick);
    });
  
    // Cleanup listeners to avoid duplication
    return () => {
      buttons.forEach((button) => {
        button.removeEventListener("click", handleButtonClick);
      });
    };
  }, [reactElement, products]);
  
  useEffect(() => {
    const searchInputs = document.querySelectorAll('[data-id="search-input"]');
    const searchResults = document.querySelectorAll(
      '[data-id="search-result"]'
    );

    function renderList(filteredItems, index) {
      if (!searchResults[index]) {
        console.error(`No search result container found for index ${index}`);
        return;
      }

      const resultElement = searchResults[index];
      resultElement.innerHTML = "";

      if (filteredItems.length === 0) {
        resultElement.innerHTML = "<p>No results found</p>";
        return;
      }

      filteredItems.forEach((item) => {
        const itemHTML = `
<div class="mb-2">
          <a class='search-item' href="/product/${createSlug(item.itemname)}">
            <div class="d-flex gap-2 align-items-center">
              <img
                src="${item.image || Placeholder}"
                alt="${item.itemname}"
                class="img"
              />
              <p class="item-name mb-0">${item.itemname}</p>
              <p class="price mb-0">
                <span class="rs-bold">Rs.</span>${
                  item.combinations[0]?.retailPrice || item.retailPrice
                }
              </p>
            </div>
          </a>
          </div>
        `;
        resultElement.innerHTML += itemHTML;
      });
    }

    const handleInput = (event, index) => {
      const query = event.target.value.toLowerCase();
      const filteredList = products?.filter((item) =>
        item.itemname.toLowerCase().includes(query)
      );
      renderList(filteredList, index);
      searchResults[index].style.display = "block"; // Show the result container
    };

    const handleOutsideClick = (event) => {
      searchResults.forEach((result) => {
        if (!result.contains(event.target)) {
          result.style.display = "none"; // Hide result container on outside click
        }
      });
    };

    searchInputs.forEach((input, index) => {
      input.addEventListener("input", (event) => handleInput(event, index));
    });

    document.addEventListener("click", handleOutsideClick);
    searchResults.forEach((_, index) => renderList(products, index));

    return () => {
      searchInputs.forEach((input, index) => {
        input.removeEventListener("input", (event) =>
          handleInput(event, index)
        );
      });
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [products, reactElement]);

  return reactElement ? (
    <>
      <MetaTags>
        <title>{domainInfo?.storeName}</title>
      </MetaTags>
      {reactElement} <OpenCart domain={domain} />
    </>
  ) : /* {loader ? (
        <>
          <div className="loader-image">
            <img src={Loader} height={"200px"} width={"200px"} alt="loader" />
          </div>
        </>
      ) : (
        <>
          {domainInfo?.storeName ? (
            <>
          
              <Box className="store">
                {domainInfo?.topNavbar && (
                  <AnnouncementBar
                    note={domainInfo?.topNavbarNote}
                    mobile={domainInfo?.topNavbarMobile}
                    email={domainInfo?.topNavbarEmail}
                  />
                )}
                <Navbar domain={domain} logo={domainInfo?.logo} />
                {domainInfo?.heroSection && (
                  <HeroSection
                    heading={domainInfo?.heroSectionHeading}
                    subHeading={domainInfo?.heroSectionSubHeading}
                    buttonText={domainInfo?.heroSectionButtonText}
                    buttonUrl={domainInfo?.heroSectionButtonLink}
                    image={domainInfo?.heroSectionImage}
                  />
                )}

                <CategorySilder categories={categories} />
                {domainInfo?.isBanners && (
                  <Features features={domainInfo?.banners} />
                )}
                <BestProduct
                  products={products}
                  domain={domain}
                  heading={domainInfo?.productsHeading}
                />
                <Footer
                  credit={domainInfo?.footerCredit}
                  logo={domainInfo?.footerLogo}
                  socialMedia={domainInfo?.socialMedia}
                  isSocialMedia={domainInfo?.isSocialMedia}
                />
                <OpenCart domain={domain} initialProducts={products} />
                {domainInfo?.isWhatsappMessageAllowed &&
                  domainInfo?.whatsapp && (
                    <WhatsappBtn number={domainInfo?.whatsapp} />
                  )}
              </Box>
            </>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <h1>
                  {" "}
                  <span style={{ fontSize: "45px", fontWeight: "800" }}>
                    {domainFetchStatus}
                  </span>{" "}
                  {domianFetchErr}
                </h1>
              </div>
            </div>
          )}
        </>
      )} */

  products === "No products found." ? (
    <div className="loader-image">No products found.</div>
  ) : (
    <div className="loader-image">
      <div className="loader"></div>
    </div>
  );
}

export default Home;
