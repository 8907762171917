import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentContext } from "../../../../store";
import Banner from "../Cart/Component/Banner";
import Placeholder from "../../../../asesst/placeholder.jpeg";
import AnnouncementBar from "../../Component/AnnouncementBar";
import BestProduct from "../../Component/BestProduct";
import Footer from "../../Component/Footer";
import Navbar from "../../Component/Navbar";
import "./style.css";
import { createView } from "../../../../apis/views";
import { decodeSlug, fetchDomain } from "../../../../helper";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import OpenCart from "../../Component/OpenCart";
import WhatsappBtn from "../../Component/WhatsappBtn";
import ContentLoader from "react-content-loader";

function Product({ domain }) {
  const { products, domainInfo, setIsModalOpen, setselectedItems } =
    useContext(ContentContext);
  const [product, setProduct] = useState({});
  const [isInCart, setIsInCart] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const { title } = useParams();
  const name = decodeSlug(title);
  const [selectedVatiant, setSelectedVatiant] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(0);
  const ProductLoader = () => (
    <ContentLoader
      speed={3}
      //   width={350}

      viewBox="0 0 476 124"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="200" height="15" />
      <rect x="0" y="26" rx="3" ry="3" width="250" height="15" />
      <rect x="0" y="50" rx="3" ry="3" width="310" height="15" />
      <rect x="0" y="75" rx="3" ry="3" width="350" height="15" />
      <rect x="0" y="100" rx="3" ry="3" width="178" height="15" />
      <rect x="0" y="125" rx="3" ry="3" width="150" height="15" />
      <rect x="0" y="150" rx="3" ry="3" width="200" height="15" />
    </ContentLoader>
  );

  const createPageView = async () => {
    try {
      if (product?.id) {
        await createView({ domain: fetchDomain(), productId: product?.id });
      }
    } catch (error) {}
  };

  const price =
    product?.itemType === "Variants"
      ? Math.min(
          ...product?.combinations?.map(
            (combination) => Number(combination?.retailPrice) || 0
          )
        )
      : Number(product?.retailPrice);

  useEffect(() => {
    if (products !== "No products found.") {
      const productData = products?.find((product) =>
        product.itemname?.toLowerCase()?.includes(name?.toLowerCase())
      );
      setSelectedPrice(price);
      setProduct(productData);

      if (product?.itemType === "Variants") {
        const selectedVariant = product?.combinations[0].variantName
        
        // .component.map(
        //   (i) => i.valueName
        // );
        setSelectedVatiant(selectedVariant);
      }
    }
  }, [products]);
  useEffect(() => {
    createPageView();
  }, [product]);

  const handleAddCart = (
    itemId,
    itemName,
    retailPrice,
    variantName,
    image,
    quantity
  ) => {
    const storedProducts = localStorage.getItem(`${domain}-carts`);
    let productsList = storedProducts ? JSON.parse(storedProducts) : [];

    let newProductsList;
    const isProductWithVariant = productsList?.some(
      (item) => item.id === itemId && item.selectedVariant === selectedVatiant
    );

    if (isProductWithVariant) {
      newProductsList = productsList.map((item) => {
        if (item.id === itemId && item.selectedVariant === variantName) {
          return { ...item, selectedQty: item.selectedQty + quantity || 1 };
        }
        return item;
      });
    } else {
      const isId = productsList?.some((item) => item.id === itemId);
      if (isId && !variantName) {
        newProductsList = productsList.map((item) => {
          if (item.id === itemId) {
            return { ...item, selectedQty: item.selectedQty + quantity || 1 };
          }
          return item;
        });
      } else {
        newProductsList = [
          {
            id: itemId,
            itemname: itemName,
            selectedVariant: variantName,
            retailPrice,
            selectedQty: quantity || 1,
            image,
          },
          ...productsList,
        ];
      }
    }

    const countBox = document.querySelectorAll('[data-id="count-box"]');
    countBox.forEach((element) => {
      element.innerHTML = newProductsList.length;
    });

    setselectedItems(newProductsList);
    localStorage.setItem(`${domain}-carts`, JSON.stringify(newProductsList));

    setIsModalOpen(true);
  };

  const checkProductInCart = () => {
    const carts = JSON.parse(localStorage.getItem(`${domain}-carts`)) || [];

    const productInCart = carts.some(
      (item) =>
        item.id === product?.id &&
        (!item.selectedVariant ||
          product?.combinations.some(
            (combination) => combination.variantName === item.selectedVariant
          ))
    );
    setIsInCart(productInCart);
  };
  useEffect(() => {
    checkProductInCart();
  }, [product]);

  const handleChangeVariant = (name, price) => {
    // const matchingCombination = product.combinations.find((combination) =>
    //   combination.component.some((component) => component.valueId === value)
    // );

    setSelectedVatiant(name);
    setSelectedPrice(price);
  };

  useEffect(() => {
    if (domainInfo?.tawkTo) {
      // Parse and insert the script into the document
      const scriptTag = document.createElement("div");
      scriptTag.innerHTML = domainInfo.tawkTo; // Assuming `tawkTo` contains the HTML with the script
      const scriptElement = scriptTag.querySelector("script");

      // Execute the script if available
      if (scriptElement) {
        const newScript = document.createElement("script");
        newScript.type = "text/javascript";
        newScript.async = true;
        newScript.innerHTML = scriptElement.innerHTML; // Set script content
        document.body.appendChild(newScript);

        // Clean up the script when component unmounts
        return () => {
          document.body.removeChild(newScript);
        };
      }
    }
  }, [domainInfo?.tawkTo]);

  let products_ = localStorage.getItem(`${domain}-carts`);

  const parsedProducts = products_ ? JSON.parse(products_) : [];

  useEffect(() => {
    if (!product?.selectedQty) {
      setProduct((prev) => ({
        ...prev,
        selectedQty: 1,
      }));
    }
  }, [product]);
  const handleDecreaseQty = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    } else {
      console.warn("Quantity cannot be less than 1");
    }
  };

  const handleIncreaseQty = () => {
    // if (quantity < parseFloat(maxQty)) {
    setQuantity(quantity + 1);
    // } else {
    //   console.warn("Quantity cannot exceed maximum limit");
    // }
  };

  return (
    <>
      {domainInfo?.topNavbar && (
        <AnnouncementBar
          note={domainInfo?.topNavbarNote}
          mobile={domainInfo?.topNavbarMobile}
          email={domainInfo?.topNavbarEmail}
        />
      )}

      <Navbar domain={domain} logo={domainInfo?.logo} />
      <Banner lable={name} />
      <div className="product_main_container">
        <motion.div
          className="container_box"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.3, ease: "easeOut" }}
        >
          <img
            src={product?.image ?? Placeholder}
            alt="Product"
            width="600px"
            height="600px"
          />
        </motion.div>
        {product?.itemname ? (
          <motion.div
            className="container_box add_main_box"
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.3, ease: "easeOut" }}
          >
            <h1 className="product_main_heading">{product?.itemname}</h1>
            <h1 className="product_main_heading pt-3 amout_size">
              Rs {selectedPrice || ""}
            </h1>
            <p className="product_text_title">{product?.description}</p>
            {product?.combinations?.map((item, i) => (
              <span
                key={i}
                onClick={() =>
                  handleChangeVariant(item.variantName, item.retailPrice)
                }
                className={`size_box ${
                  selectedVatiant?.includes(item.variantName) ? "active" : ""
                }`}
                style={{
                  padding: "5px 10px",
                  border: "1px solid #ccc",
                  marginLeft:"5px",
                  borderRadius: "5px",
                  minWidth: "68px",
                  textAlign: "center",
                  cursor: "pointer",
                  backgroundColor: selectedVatiant?.includes(item.variantName)
                    ? "#f0f0f0"
                    : "white",
                  fontSize: "14px",
                }}
              >
                {item.variantName}
              </span>
            ))}
            {/* {product?.variants?.map((variant, index) => {
              return variant?.variantOptionName?.toLowerCase() === "color" ? (
                <div className="color_selector mt-4" key={index}>
                  <label htmlFor="color" className="selec_color">
                    Select Color:{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                        fontSize: 16,
                      }}
                    >
                      {selectedVatiant?.forEach((i) => i)}
                    </span>
                  </label>
                  <div
                    className="color_options ml-2"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {variant?.variantValues?.map((color, index) => (
                      <span
                        key={index}
                        className={`color_circle ${
                          selectedVatiant?.includes(color?.label)
                            ? "active-color"
                            : ""
                        }`}
                        onClick={() =>
                          handleChangeVariant(color?.label, color.value)
                        }
                        style={{
                          backgroundColor: color?.label,
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          cursor: "pointer",
                          border: "2px solid #ccc",
                        }}
                      ></span>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="size_selector mt-5">
                  <label htmlFor="size" style={{ fontSize: 14 }}>
                    Select:{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                        fontSize: 16,
                      }}
                    >
                      {selectedVatiant}
                    </span>
                  </label>
                  <div
                    className="size_options ml-2"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "5px",
                      marginTop: "10px",
                    }}
                  >
                    {variant?.variantValues?.map((size, index) => (
                      <span
                        key={index}
                        onClick={() =>
                          handleChangeVariant(size?.label, size.value)
                        }
                        className={`size_box ${
                          selectedVatiant?.includes(size?.label) ? "active" : ""
                        }`}
                        style={{
                          padding: "5px 10px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          minWidth: "68px",
                          textAlign: "center",
                          cursor: "pointer",
                          backgroundColor: selectedVatiant?.includes(
                            size?.label
                          )
                            ? "#f0f0f0"
                            : "white",
                          fontSize: "14px",
                        }}
                      >
                        {size?.label}
                      </span>
                    ))}
                  </div>
                </div>
              );
            })} */}

            {product?.combinations?.filter(
              (e) => e.variantName == selectedVatiant
            )?.length > 0 ? (
              <div style={{ marginTop: "35px" }}>
                {product?.combinations
                  ?.filter((e) => e.variantName == selectedVatiant)
                  .map((variant, index) => (
                    <>
                      {variant?.count > 0 ? (
                        <div key={index}>
                          <td className="product_add">
                            <span className="add_product_text">
                              <span
                                onClick={() => {
                                  handleDecreaseQty();
                                }}
                              >
                                -
                              </span>
                              {quantity}
                              <span
                                onClick={() => {
                                  handleIncreaseQty(variant?.count);
                                }}
                              >
                                +
                              </span>
                            </span>
                          </td>
                        </div>
                      ) : (
                        <div>No Stock Available</div>
                      )}
                    </>
                  ))}
              </div>
            ) : (
              <>
                {product?.count > 0 ? (
                  <div>
                    <td className="product_add">
                      <span className="add_product_text">
                        <span
                          onClick={() => {
                            handleDecreaseQty();
                          }}
                        >
                          -
                        </span>
                        {quantity}
                        <span
                          onClick={() => {
                            handleIncreaseQty();
                          }}
                        >
                          +
                        </span>
                      </span>
                    </td>
                  </div>
                ) : (
                  <div>No Stock Available</div>
                )}
              </>
            )}

            <div className="mt-5 d-flex w-100">
              {product?.combinations?.filter(
                (e) => e.variantName == selectedVatiant
              )?.length > 0 ? (
                product?.combinations
                  ?.filter((e) => e.variantName == selectedVatiant)
                  .map((variant, index) => (
                    <>
                      {variant?.count > 0 ? (
                        <button
                          onClick={() =>
                            handleAddCart(
                              product?.id,
                              product?.itemname,
                              variant?.retailPrice,
                              selectedVatiant,
                              product?.image,
                              quantity
                            )
                          }
                          className="add_cart_btn"
                        >
                          Add To Cart
                        </button>
                      ) : (
                        <div></div>
                      )}
                    </>
                  ))
              ) : (
                <>
                  {product?.count > 0 ? (
                    <button
                      onClick={() =>
                        handleAddCart(
                          product?.id,
                          product?.itemname,
                          product?.retailPrice,
                          "",
                          product?.image,
                          quantity
                        )
                      }
                      className="add_cart_btn"
                    >
                      Add To Cart
                    </button>
                  ) : (
                    <div></div>
                  )}
                </>
              )}
              <div></div>
            </div>
          </motion.div>
        ) : (
          <ProductLoader />
        )}
      </div>
      <BestProduct initialVisible={3} products={products} domain={domain} />
      <Footer
        credit={domainInfo?.footerCredit}
        logo={domainInfo?.footerLogo}
        socialMedia={domainInfo?.socialMedia}
        isSocialMedia={domainInfo?.isSocialMedia}
      />
      <OpenCart domain={domain} />
      {domainInfo?.isWhatsappMessageAllowed && domainInfo?.whatsapp && (
        <WhatsappBtn number={domainInfo?.whatsapp} />
      )}
    </>
  );
}

export default Product;
