import React from 'react'
import './style.css'
import ourCLientimg from '../../../../asesst/OurClients.png'
import shopify from '../../../../asesst/shopify.png'
import Social from '../../../../asesst/social.png'
import microsoft from '../../../../asesst/microsoft.png'
import dell from '../../../../asesst/dell.png'
import summsng from '../../../../asesst/samsung.png'
import google from '../../../../asesst/google.png'
import customapi from '../../../../asesst/cutomapi.png'
function OurCLient() {
    const cardData = [
        {
            imgSrc: shopify,
            title: 'Shopify',
            description: 'Integrate with Shopify to enhance your eCommerce experience. Our POS system syncs seamlessly with your Shopify store, providing real-time updates on inventory, orders, and customer data. Simplify your multi-channel management and boost your online sales efficiency.',
            buttonText: 'Coming soon'
        },
        {
            imgSrc: Social,
            title: 'Woo Commerce',
            description: 'Effortlessly connect your Tijarah Soft POS with WooCommerce to unify your online and in-store operations. Sync inventory, manage orders, and update product details in real-time, ensuring consistency and accuracy across all sales channels.',
            buttonText: 'Coming soon'
        },
        {
            imgSrc: customapi, // Add the correct image path if needed
            title: 'Custom Api',
            description: 'For businesses with unique needs, Tijarah Soft offers custom API integrations. Tailor our POS system to work with your specific applications or third-party services, ensuring a perfect fit for your operational requirements. Enhance functionality and streamline processes with our flexible API solutions.',
            buttonText: 'Coming soon'
        }
    ];
    return (
        <div className='compelet__screen_perfect  outCLint__main'>

            {/* <div>
                <img src={ourCLientimg} alt='headingImg' />
                <p className='our_pera'><span>Took</span> a look at our <span>Clients</span></p>
            </div>

            <div className='card_sett'>
                <div className='cards_siz'>
                    <div>
                        <img src={microsoft} alt='microsoft' />

                    </div>
                </div>
                <div className='cards_siz'>
                    <img src={dell}  alt='Dell'/>
                </div>
                <div className='cards_siz'>
                    <img src={summsng} alt='Sumsang' />
                </div>
                <div className='cards_siz'>
                    <img src={google} alt='Google' />
                </div>
            </div> */}


            <div>
                <div>
                    <h3 className='our_pera  '><span>Seamless Integration</span> with <span>Tijarah Soft</span></h3>
                    <p className='sec_Pera_out' >Tijarah Soft offers powerful integration capabilities to streamline your business operations across various platforms. Our POS system supports seamless integration with:</p>
                </div>
            </div>
            <div className='postion_stye'>

            </div>
            <div className='cards__setting '>
                {cardData.map((card, index) => (
                    <div key={index}>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center gap-2'>
                                {card.imgSrc && (
                                    <div>
                                        <img src={card.imgSrc} alt={card.title} width={40} />
                                    </div>
                                )}
                                <div>
                                    <h3 className='card__head'>{card.title}</h3>
                                </div>
                            </div>
                            <div>
                                <button className='btn_style'>{card.buttonText}</button>
                            </div>
                        </div>
                        <p className='card_Pera py-3'>
                            {card.description}
                        </p>
                    </div>
                ))}



            </div>
        </div>
    )
}

export default OurCLient