import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Placeholder from "../../../../../asesst/placeholder.jpeg";
import { createSlug } from "../../../../../helper";
import { ContentContext } from "../../../../../store";
import { motion } from "framer-motion";

const ProductCard = ({ src, title, product, link }) => {
  const { setIsModalOpen, setselectedItems, domain } =
    useContext(ContentContext);

  const variant = product.combinations?.[0] || {};
  const handleAddCart = (itemId, itemName, retailPrice, variantName, image) => {
    const storedProducts = localStorage.getItem(`${domain}-carts`);
    let productsList = storedProducts ? JSON.parse(storedProducts) : [];

    setIsModalOpen(true); 

    let newProductsList;
    const isProductWithVariant = productsList?.some(
      (item) => item.id === itemId && item.selectedVariant === variantName
    );

    if (isProductWithVariant) {
      newProductsList = productsList.map((item) => {
        if (item.id === itemId && item.selectedVariant === variantName) {
          return { ...item, selectedQty: item.selectedQty + 1 };
        }
        return item;
      });
    } else {
      const isId = productsList?.some((item) => item.id === itemId);
      if (isId && !variantName) {
        newProductsList = productsList.map((item) => {
          if (item.id === itemId) {
            return { ...item, selectedQty: item.selectedQty + 1 };
          }
          return item;
        });
      } else {
        newProductsList = [
          {
            id: itemId,
            itemname: itemName,
            selectedVariant: variantName,
            retailPrice,
            selectedQty: 1,
            image,
          },
          ...productsList,
        ];
      }
    }
    const countBox = document.querySelectorAll('[data-id="count-box"]');
    countBox.forEach((element) => {
      element.innerHTML = newProductsList.length;
    });
    setselectedItems(newProductsList);
    localStorage.setItem(`${domain}-carts`, JSON.stringify(newProductsList));
  };

  return (
    <section className="card-product product-card card-slider-item">
      <section className="card-media media--hover-effect">
        <img
          src={src || Placeholder}
          sizes="(min-width: 1100px) 960px, (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)"
          alt={title}
          loading="lazy"
          className="motion-reduce lazyloaded"
        />
        <img
          src={src || Placeholder}
          sizes="(min-width: 1100px) 960px, (min-width: 750px) calc((100vw - 130px) / 2), calc((100vw - 50px) / 2)"
          alt={title}
          loading="lazy"
          className="motion-reduce lazyloaded"
        />
        <a className="card-link" href={link}></a>
        <div
          className={`stock-tag ${variant.count || product.count ? "d-none" : "d-flex"
            }`}
        >
          <p>Out Of Stock</p>
        </div>
      </section>
      <section className="position-relative">
        <section className="card-action">
          <section className="variants">
            {variant.count || product.count ? (
              <button
                className="button button-ATC colored-btn"
                onClick={() =>
                  handleAddCart(
                    product.id,
                    title,
                    variant.retailPrice || product.retailPrice,
                    variant.variantName,
                    src
                  )
                }
              >
                Quick Add
              </button>
            ) : null}
          </section>
        </section>
        <h3 className="card__heading">
          <a
            className="card-title link-underline card-title-ellipsis card-title-change"
            href={link}
          >
            {title} {variant.variantName ? `- ${variant.variantName}` : ""}
          </a>
        </h3>
        <section className="card-price">
          <span className="fw-bold unselectable-block text-dark">Rs.</span>
          <span className="money">
            {variant.retailPrice || product.retailPrice}
          </span>
        </section>
      </section>
    </section>
  );
};

export default ProductCard;
