import React, { useEffect } from "react";
import Banner from "./Component/Banner";
import ProductTable from "./Component/ProductTable";
import Navbar from "../../Component/Navbar";
import Footer from "../../Component/Footer";
import AnnouncementBar from "../../Component/AnnouncementBar";
import EmptyCart from "./Component/EmptyCart";
import OpenCart from "../../Component/OpenCart";
import { ContentContext } from "../../../../store";
import { useContext } from "react";
import WhatsappBtn from "../../Component/WhatsappBtn";

const Cart = ({ domain }) => {
  const { domainInfo } = useContext(ContentContext);
  let products = localStorage.getItem(`${domain}-carts`);

  // Safely parse products or default to an empty array if there's an issue
  const parsedProducts = products ? JSON.parse(products) : [];



  useEffect(() => {
    if (domainInfo?.tawkTo) {
      // Parse and insert the script into the document
      const scriptTag = document.createElement("div");
      scriptTag.innerHTML = domainInfo.tawkTo; // Assuming `tawkTo` contains the HTML with the script
      const scriptElement = scriptTag.querySelector("script");

      // Execute the script if available
      if (scriptElement) {
        const newScript = document.createElement("script");
        newScript.type = "text/javascript";
        newScript.async = true;
        newScript.innerHTML = scriptElement.innerHTML; // Set script content
        document.body.appendChild(newScript);

        // Clean up the script when component unmounts
        return () => {
          document.body.removeChild(newScript);
        };
      }
    }
  }, [domainInfo?.tawkTo]);

  return (
    <div className="cart ">
      {domainInfo?.topNavbar && (
        <AnnouncementBar
          note={domainInfo?.topNavbarNote}
          mobile={domainInfo?.topNavbarMobile}
          email={domainInfo?.topNavbarEmail}
        />
      )}
      <Navbar domain={domain} />
      <Banner lable="Shopping Cart" />

      <div className="mt-2">
        {parsedProducts.length === 0 ? (
          <div className="min-height">
            {" "}
            <EmptyCart />
          </div>
        ) : (
          <ProductTable initialProducts={parsedProducts} domain={domain} />
        )}
      </div>

      <div className="mt-3">
        <Footer
          credit={domainInfo?.footerCredit}
          logo={domainInfo?.footerLogo}
          socialMedia={domainInfo?.socialMedia}
          isSocialMedia={domainInfo?.isSocialMedia}
        />
        <OpenCart domain={domain} initialProducts={products} />
      </div>
      {domainInfo?.isWhatsappMessageAllowed && domainInfo?.whatsapp && (
        <WhatsappBtn number={domainInfo?.whatsapp} />
      )}
    </div>
  );
};

export default Cart;
