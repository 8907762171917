


import React from 'react'
import CustomNavbar from '../../Component/Navber'
import './App.css'
import LetsStart from '../../Component/LetsStart';
import Footer from '../../Component/Footer';
import Inventry from '../../../../asesst/inventryweb.png'
import salesreturn from '../../../../asesst/sales-return.png'
import customer from '../../../../asesst/customer (3).png'
import Acount from '../../../../asesst/acountimg.png'
import Store from '../../../../asesst/store.png'
import UserRoll from '../../../../asesst/userRoll.png'
import mobile from '../../../../asesst/customizeMobile.png'
import Reports from '../../../../asesst/reports.png'
import Backup from '../../../../asesst/backup.png'
import customerimages from '../../../../asesst/customerimages.png'
import { AccesIcon, ActivtyIcon, AnlyticsIcon, AtmIcon, CheckoutIcon, CloudIcon, ControlIcon, EncryptionIcon, FlexibleIcon, HistryIcon, LoginIcon, LoyaltyIcon, OpraionIcon, PaymetIcon, ProfileIcon, RecoveryIcon, ReportIcon, SpecificIcon, TrackingIcon, TransferIcon, SupportIcon } from '../../../../component/icon';
const data = [
    {
        heading: "Point of Sale (POS) System",
        text: "Our intuitive POS system is at the heart of Tijarah Soft, enabling you to process sales quickly and efficiently...",
        imgSrc: salesreturn,
        features: [
            {
                title: "Quick Checkout",
                text: "Speed up transactions with fast and reliable checkout processes.",
                imgSrc: <CheckoutIcon color='#604be8' size='35px' />
            },
            {
                title: "Multiple Payment Methods",
                text: "Accept cash, credit cards, mobile payments, and more.",
                imgSrc: <PaymetIcon color='#604be8' size='35px' />
            },
            {
                title: "Sales Reporting",
                text: "Gain insights into your sales data with detailed reports.",
                imgSrc: <ReportIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Inventory Management",
        text: "Keep track of your stock levels effortlessly with Tijarah Soft's robust inventory management features. Monitor inventory in real time, automate reordering, and reduce stockouts to ensure you always have the products your customers want.",
        imgSrc: Inventry,
        features: [
            {
                title: "Real-Time Inventory Tracking",
                text: " Know what's in stock at all times.",
                imgSrc: <TrackingIcon color='#604be8' size='35px' />
            },
            {
                title: "Automated Reordering",
                text: "Set thresholds to automatically reorder products..",
                imgSrc: <AtmIcon color='#604be8' size='35px' />
            },
            {
                title: "Inventory Reports",
                text: "Analyze stock levels, turnover rates, and more.",
                imgSrc: <ReportIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Customer Relationship Management (CRM)",
        text: "Build and maintain strong relationships with your customers using our integrated CRM tools. Manage customer profiles, track purchase history, and tailor your marketing efforts to improve customer loyalty and drive repeat business.",
        imgSrc: customer,
        features: [
            {
                title: "Customer Profiles",
                text: " Store detailed customer information and preferences.",
                imgSrc: <ProfileIcon color='#604be8' size='25px' />
            },
            {
                title: "Purchase History",
                text: "Track past transactions to offer personalized service.",
                imgSrc: <HistryIcon color='#604be8' size='25px' />
            },
            {
                title: "Loyalty Programs",
                text: "Reward your customers and encourage repeat purchases.",
                imgSrc: <LoyaltyIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Multi-Store Management        ",
        text: "Manage multiple stores from a single platform with ease. Tijarah Soft allows you to oversee operations, track sales, and manage inventory across all your locations, ensuring consistency and efficiency.",
        imgSrc: Store,
        features: [
            {
                title: "Centralized Control",
                text: " Manage all your stores from one dashboard.",
                imgSrc: <ControlIcon color='#604be8' size='25px' />
            },
            {
                title: "Inventory Transfers",
                text: "Move stock between stores effortlessly.",
                imgSrc: <TransferIcon color='#604be8' size='25px' />
            },
            {
                title: "Store-Specific Reports",
                text: " Analyze performance at each location.",
                imgSrc: <SpecificIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Accounting and Financial Management       ",
        text: "Stay on top of your finances with Tijarah Soft's built-in accounting features. Track expenses, manage invoices, and generate financial reports to keep your business financially healthy and compliant",
        imgSrc: Acount,
        features: [
            {
                title: "Expense Tracking",
                text: " Monitor and categorize business expenses.",
                imgSrc: <TrackingIcon color='#604be8' size='25px' />
            },
            {
                title: "Invoice Management",
                text: " Create, send, and track invoices with ease.",
                imgSrc: <TransferIcon color='#604be8' size='25px' />
            },
            {
                title: "Financial Reports",
                text: " Generate balance sheets, profit and loss statements, and more.",
                imgSrc: <SpecificIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "User Access Control      ",
        text: "Maintain security and control over your business operations with customizable user access levels. Assign roles and permissions to ensure that your team members have the appropriate access to the tools they need.",
        imgSrc: UserRoll,
        features: [
            {
                title: "Role-Based Access",
                text: " Define roles with specific permissions.",
                imgSrc: <AccesIcon color='#604be8' size='25px' />
            },
            {
                title: "Activity Logs",
                text: " Monitor user activity for accountability.",
                imgSrc: <ActivtyIcon color='#604be8' size='25px' />
            },
            {
                title: "Secure Login",
                text: " Protect your data with secure authentication methods.",
                imgSrc: <LoginIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Offline Mode     ",
        text: "Tijarah Soft ensures uninterrupted business operations, even when the internet is down. Our offline mode allows you to continue processing transactions and managing inventory, with automatic syncing when the connection is restored.",
        imgSrc: Store,
        features: [
            {
                title: "Seamless Offline Operation",
                text: "Continue working without internet access.",
                imgSrc: <OpraionIcon color='#604be8' size='25px' />
            },
            {
                title: "Automatic Syncing",
                text: " Sync data automatically when you're back online.",
                imgSrc: <TransferIcon color='#604be8' size='25px' />
            },
            {
                title: "Reliable Performance",
                text: "Ensure smooth operations no matter the connectivity.",
                imgSrc: <SpecificIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Customizable Dashboards    ",
        text: "Get a clear overview of your business with customizable dashboards. Track key metrics, monitor sales, and stay informed with real-time data presented in a way that makes sense to you.",
        imgSrc: mobile,
        features: [
            {
                title: "Real-Time Analytics",
                text: "Access up-to-date information at a glance..",
                imgSrc: <AnlyticsIcon color='#604be8' size='25px' />
            },
            {
                title: "Custom Widgets",
                text: " Tailor your dashboard to display the data that matters most.",
                imgSrc: <TransferIcon color='#604be8' size='25px' />
            },
            {
                title: "Visual Reports",
                text: "Make informed decisions with easy-to-understand charts and graphs.",
                imgSrc: <ReportIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Comprehensive Reporting   ",
        text: "Make data-driven decisions with Tijarah Soft's powerful reporting tools. Generate detailed reports on sales, inventory, customer behavior, and financial performance to gain insights that drive your business forward.",
        imgSrc: Reports,
        features: [
            {
                title: "Sales Reports",
                text: "Analyze trends, top-selling products, and revenue.",
                imgSrc: <ReportIcon color='#604be8' size='25px' />
            },
            {
                title: "Inventory Reports",
                text: " Track stock levels, reorder points, and inventory turnover.",
                imgSrc: <ReportIcon color='#604be8' size='25px' />
            },
            {
                title: "Financial Reports",
                text: " Monitor your business's financial health with ease.",
                imgSrc: <ReportIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Secure Cloud Backup ",
        text: "Protect your business data with Tijarah Soft's secure cloud backup solution. Ensure your information is safe, accessible, and recoverable in the event of hardware failure or other disruptions.",
        imgSrc: Backup,
        features: [
            {
                title: "Automated Backups",
                text: "Schedule regular backups to the cloud.",
                imgSrc: <CloudIcon color='#604be8' size='25px' />
            },
            {
                title: "Data Encryption",
                text: " Safeguard your data with industry-standard encryption.",
                imgSrc: <EncryptionIcon color='#604be8' size='25px' />
            },
            {
                title: "Easy Recovery",
                text: "Restore your data quickly and easily when needed.",
                imgSrc: <RecoveryIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "Scalable Solutions",
        text: "Whether you're a small business or growing enterprise, Tijarah Soft scales with you. Customize features, add users, and expand your operations effortlessly as your business evolves.",
        imgSrc: Store,
        features: [
            {
                title: "Flexible Plans",
                text: "Choose the plan that fits your business needs.",
                imgSrc: <FlexibleIcon color='#604be8' size='25px' />
            },
            {
                title: "User Management",
                text: " Easily add or remove users as your team grows.",
                imgSrc: <ProfileIcon color='#604be8' size='25px' />
            },
            {
                title: "Modular Features",
                text: "Add or remove features based on your business requirements.",
                imgSrc: <SpecificIcon color='#604be8' size='25px' />
            },
        ],
    },
    {
        heading: "24/7 Customer Support",
        text: "We understand that your business doesn't stop, and neither do we. Tijarah Soft offers 24/7 customer support to assist you whenever you need help, ensuring that any issues are resolved quickly and efficiently",
        imgSrc: customerimages,
        features: [
            {
                title: "Dedicated Support Team",
                text: " Get help from knowledgeable professionals.",
                imgSrc: <SupportIcon color='#604be8' size='25px' />
            },
            {
                title: "Multiple Support Channels",
                text: "  Reach us via phone, email, or live chat.",
                imgSrc: <SupportIcon color='#604be8' size='25px' />
            },
            {
                title: "Comprehensive Help Center",
                text: " Access tutorials, guides, and FAQs anytime.",
                imgSrc: <SupportIcon color='#604be8' size='25px' />
            },
        ],
    },
    // Add more sections here
];

function Features() {
    return (
        <div>
            <CustomNavbar />
            <div className='about__sub'>
                <div className='w-100'>
                    <h1 className='about_heading text-white'>Features</h1>
                    <a href='/' className='home_link'>Home</a>
                    <span className='slash_tyle'>/</span>
                    <a className='about_link' href='/features'>Features</a>
                </div>
            </div>

            <div className='desktop_main_cards w-100'>
                {data.map((item, index) => (
                    <div className={`cards_feature mt-5 gap-4 ${index % 2 === 0 ? '' : 'reverse'}`} key={index}>
                        <div className=' box__features'>
                            {index % 2 === 0 ? (
                                <div className='bg_img_style'>
                                    <img src={item.imgSrc} alt={item.heading} className='Feature__img' />

                                </div>
                            ) : (
                                <div className='content_cards'>
                                    <h3 className='main_heading_cards'>{item.heading}</h3>
                                    <p>{item.text}</p>
                                    {item.features.map((feature, i) => (
                                        <div className='d-flex  gap-4 py-2' key={i}>
                                            {feature.imgSrc}
                                            <div>
                                                <h3 className='crad_head'>{feature.title}</h3>
                                                <p className='card_text'>{feature.text}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className=' box__features'>
                            {index % 2 === 0 ? (
                                <div className='content_cards'>
                                    <h3 className='main_heading_cards'>{item.heading}</h3>
                                    <p>{item.text}</p>
                                    {item.features.map((feature, i) => (
                                        <div className='d-flex  gap-4 py-2' key={i}>
                                            {feature.imgSrc}
                                            <div>
                                                <h3 className='crad_head'>{feature.title}</h3>
                                                <p className='card_text'>{feature.text}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className='bg_img_style'>
                                    <img  src={item.imgSrc} alt={item.heading} className='Feature__img' />

                                </div>
                            )
                            }
                        </div>
                    </div>
                ))}
            </div>
            <div className='main_box_cards'>

                {data.map((item, index) => (
                    <div className={'cards_feature mt-5 gap-4 '} key={index}>
                        <div className=' box__features'>

                            <div className='bg_img_style'>
                                <img width='100%' src={item.imgSrc} alt={item.heading} className='Feature__img' />

                            </div>

                            <div className='content_cards'>
                                <h3 className='main_heading_cards'>{item.heading}</h3>
                                <p>{item.text}</p>
                                {item.features.map((feature, i) => (
                                    <div className='d-flex  gap-4 py-2' key={i}>
                                        {feature.imgSrc}
                                        <div>
                                            <h3 className='crad_head'>{feature.title}</h3>
                                            <p className='card_text'>{feature.text}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                        {/* <div className=' box__features'>
                            {index % 2 === 0 ? (
                                <div className='content_cards'>
                                    <h3 className='main_heading_cards'>{item.heading}</h3>
                                    <p>{item.text}</p>
                                    {item.features.map((feature, i) => (
                                        <div className='d-flex  gap-4 py-2' key={i}>
                                            {feature.imgSrc}
                                            <div>
                                                <h3 className='crad_head'>{feature.title}</h3>
                                                <p className='card_text'>{feature.text}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className='bg_img_style'>
                                    <img width='100%' src={item.imgSrc} alt={item.heading} className='Feature__img' />

                                </div>
                            )
                            }
                        </div> */}
                    </div>
                ))}
            </div>
            <LetsStart />
            <Footer />
        </div>
    )
}

export default Features;
