// import React from "react";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import './style.css';

// const ContactNumberInput = ({ setData, data, name, bgColor = "#FFF" }) => {
//   const handlePhoneChange = (value) => {
//     setData((prev) => ({ ...prev, [name]: value }));
//   };

//   return (
//     <div>
//       <PhoneInput
//         country={"pk"}
//         value={data[name]}
//         onChange={handlePhoneChange}
//         placeholder="Enter contact number"
//         inputStyle={{
//           width: "100%",
//           height: "53px",
//           borderRadius: "5px",
//           outline: "none",
//           background: bgColor, // Dynamic background color
//           color: "black",
//         }}
//         buttonClass="custom-button-style"
//         containerClass="custom-phone-container"
//       />
//     </div>
//   );
// };

// export default ContactNumberInput;

import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import './style.css';

const ContactNumberInput = ({ setData, data, name, bgColor = "#FFF" }) => {
  const handlePhoneChange = (value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <PhoneInput
        country={"pk"} // Default country code for Pakistan
        value={data[name]}
        onChange={handlePhoneChange}
        placeholder="Enter contact number"
        inputStyle={{
          width: "100%",
          height: "53px",
          borderRadius: "5px",
          outline: "none",
          background: bgColor, // Dynamic background color
          color: "black",
        }}
        buttonClass="custom-button-style"
        containerClass="custom-phone-container"
      />
    </div>
  );
};

export default ContactNumberInput;

