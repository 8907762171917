export const domainList = ['tijarahsoft', 'www.tijarahsoft.com', "www" , 'localhost', "tijarahsoft.com"];

export const citiesData = [
    { value: 'Karachi', label: 'Karachi' },
    { value: 'Lahore', label: 'Lahore' },
    { value: 'Islamabad', label: 'Islamabad' },
    { value: 'Faisalabad', label: 'Faisalabad' },
    { value: 'Rawalpindi', label: 'Rawalpindi' },
    { value: 'Multan', label: 'Multan' },
    { value: 'Hyderabad', label: 'Hyderabad' },
    { value: 'Gujranwala', label: 'Gujranwala' },
    { value: 'Peshawar', label: 'Peshawar' },
    { value: 'Quetta', label: 'Quetta' },
    { value: 'Sialkot', label: 'Sialkot' },
    { value: 'Bahawalpur', label: 'Bahawalpur' },
    { value: 'Sukkur', label: 'Sukkur' },
    { value: 'Larkana', label: 'Larkana' },
    { value: 'Nawabshah', label: 'Nawabshah' },
    { value: 'Mardan', label: 'Mardan' },
    { value: 'Abbottabad', label: 'Abbottabad' },
    { value: 'Dera Ghazi Khan', label: 'Dera Ghazi Khan' },
    { value: 'Gujrat', label: 'Gujrat' },
    { value: 'Jhelum', label: 'Jhelum' },
    { value: 'Mirpur Khas', label: 'Mirpur Khas' },
    { value: 'Kasur', label: 'Kasur' },
    { value: 'Mianwali', label: 'Mianwali' },
    { value: 'Sheikhupura', label: 'Sheikhupura' },
    { value: 'Okara', label: 'Okara' },
    { value: 'Attock', label: 'Attock' },
    { value: 'Bannu', label: 'Bannu' },
    { value: 'Dera Ismail Khan', label: 'Dera Ismail Khan' },
    { value: 'Tando Allahyar', label: 'Tando Allahyar' },
    { value: 'Sargodha', label: 'Sargodha' },
    { value: 'Khanewal', label: 'Khanewal' },
    { value: 'Jhang', label: 'Jhang' },
    { value: 'Rajanpur', label: 'Rajanpur' },
    { value: 'Chiniot', label: 'Chiniot' },
    { value: 'Nowshera', label: 'Nowshera' },
    { value: 'Pano Aqil', label: 'Pano Aqil' },
    { value: 'Karak', label: 'Karak' },
    { value: 'Zhob', label: 'Zhob' },
    { value: 'Khushab', label: 'Khushab' },
    { value: 'Hangu', label: 'Hangu' },
    { value: 'Kotli', label: 'Kotli' },
    { value: 'Muzaffarabad', label: 'Muzaffarabad' },
    { value: 'Gilgit', label: 'Gilgit' },
    { value: 'Skardu', label: 'Skardu' },
    { value: 'Chitral', label: 'Chitral' },
    { value: 'Swat', label: 'Swat' },
    { value: 'Charsadda', label: 'Charsadda' },
    { value: 'Mansehra', label: 'Mansehra' },
    { value: 'Bhakkar', label: 'Bhakkar' },
    { value: 'Shikarpur', label: 'Shikarpur' },
    { value: 'Ghotki', label: 'Ghotki' },
    { value: 'Badin', label: 'Badin' },
    { value: 'Thatta', label: 'Thatta' },
    { value: 'Dadu', label: 'Dadu' },
    { value: 'Khairpur', label: 'Khairpur' },
    { value: 'Umerkot', label: 'Umerkot' },
    { value: 'Jacobabad', label: 'Jacobabad' },
    { value: 'Turbat', label: 'Turbat' },
    { value: 'Gwadar', label: 'Gwadar' },
    { value: 'Ziarat', label: 'Ziarat' },
    { value: 'Sibi', label: 'Sibi' },
    { value: 'Kohat', label: 'Kohat' },
    { value: 'Haripur', label: 'Haripur' },
    { value: 'Tank', label: 'Tank' },
    { value: 'Matiari', label: 'Matiari' },
    { value: 'Nankana Sahib', label: 'Nankana Sahib' },
    { value: 'Vehari', label: 'Vehari' },
    { value: 'Toba Tek Singh', label: 'Toba Tek Singh' },
    { value: 'Pakpattan', label: 'Pakpattan' },
    { value: 'Narowal', label: 'Narowal' },
    { value: 'Lodhran', label: 'Lodhran' },
    { value: 'Muzafargarh', label: 'Muzafargarh' },
    { value: 'Hafizabad', label: 'Hafizabad' },
    { value: 'Kot Adu', label: 'Kot Adu' },
    { value: 'Khuzdar', label: 'Khuzdar' },
    { value: 'Mastung', label: 'Mastung' },
];

