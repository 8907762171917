import React from 'react';
import './style.css';
import usercard from '../../../../asesst/usercarcimg.png';
import secUserCard from '../../../../asesst/secUserCard.png';
import Button from '../../../../component/Button';

function CardUser() {
  const contentArray = [
    {
      heading: 'Basic Plan',
      text: 'Our Basic Plan is designed for businesses that need a robust offline POS solution. It provides all the core features for managing sales and inventory without requiring an internet connection. Ideal for single-location stores that operate independently, this plan ensures smooth and efficient operations in a completely offline environment.',
      buttonText: 'Start Free Trial',
      imgSrc: usercard,
    },
    {
      heading: 'Pro Plan',
      text: 'The Pro Plan is perfect for businesses looking to expand their reach with both offline and online capabilities. It includes all the features of the Basic Plan, plus advanced tools for managing eCommerce integrations and user permissions. Whether you’re running multiple locations or integrating with online sales platforms, the Pro Plan offers comprehensive support to streamline your operations and enhance your business performance.',
      buttonText: 'Start Free Trial',
      imgSrc: secUserCard,
    },
    // Add more objects as needed
  ];

  return (
    <div className='userCard__main'>
      <div className='compelet__screen_perfect user__main'>
        <div className='container'>
          <div className='row'>
            {contentArray.map((item, index) => (
              <div className='col-12 d-flex' key={index}>
                {index % 2 === 0 ? (
                  <>
                    <div className='useCard'>
                      <div className='conent_card'>
                        <h3 className='userCard_head  py-2'>{item.heading}</h3>
                        <p className='userCardText'>{item.text}</p>
                        <Button label={item.buttonText} whiteBlue />
                      </div>
                      <div className='conent_card  sec_card_content'>
                        <img src={item.imgSrc} width='100%' height={450} alt='' />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='useCard  usecard_sec'>
                      <div className='conent_card '>
                        <img src={item.imgSrc} width='100%'  height={450} alt='' />
                      </div>
                      <div className='conent_card'>
                        <h3 className='userCard_head  fs-1 py-2'>{item.heading}</h3>
                        <p className='userCardText'>{item.text}</p>
                        <Button label={item.buttonText} whiteBlue />

                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardUser;
