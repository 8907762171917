import React from 'react'
import '../HeroSection/style.css'
import './style.css'
import Pso from '../../../../asesst/pso.jpg'
function About() {
    return (
        <div className='main__hero_sec  compelet__screen_perfect'>
            <h1 className='About_us_heading' id='about'>About Us</h1>

            <div className='about__main'>
                <div className='about_box'>
                    <h1 className='about_head'><span>Empowering Businesses</span> with Seamless<span> POS Solutions</span></h1>
                    <p className='about__pera'>At Tijarah Soft, we're committed to providing powerful and user-friendly POS solutions designed to help businesses thrive. Our mission is to simplify retail operations with a focus on efficiency, scalability, and innovation. With a deep understanding of the challenges faced by modern businesses, we offer tools that streamline sales, inventory, and customer management, allowing our clients to focus on growth. Whether you're a small retailer or a large enterprise, Tijarah Soft is here to support your journey to success.</p>
                    <a href='/about-us' className='link_syle'> Learn More</a>
                </div>
                <div className='about_box'>
                    <img src={Pso} alt='' style={{
                        width: 708,
                        height: 450
                    }} />
                </div>
            </div>



        </div>
    )
}

export default About