import React, { useContext, useEffect, useMemo } from "react";

import { ContentContext } from "../../../../store";

function Footer() {
  const { domainInfo, storeName } = useContext(ContentContext);

  

  const htmlString = domainInfo?.footer || "";

  const processedHtmlString = useMemo(() => {
    const rawHtml = htmlString?.replaceAll("[store_name]", storeName);
    return rawHtml;
  }, [htmlString]);

  if (!domainInfo || !domainInfo.footer) {
    return null;
  }

  return <div dangerouslySetInnerHTML={{ __html: processedHtmlString }} />;
}

export default Footer;
